import React, { useState, useEffect } from 'react';
import './ImageSlider.css'
const slides = [
    { image: require('../../assets/bg.jpg'), quote: 'Hello World' },
    { image: require('../../assets/bg2.png'), quote: 'Tordur' },
    { image: require('../../assets/bg3.png'), quote: 'Bao bao' },
  ];
const ImageSlider = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const nextSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    };
  
    const prevSlide = () => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    };
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        nextSlide();
      }, 3000); // Change slide every 3000 milliseconds (3 seconds)
  
      return () => {
        clearInterval(intervalId); // Clear the interval when the component is unmounted
      };
    }, [currentIndex]); // Re-run t
   

  return (
    <div>
    {/* Display individual slide content here */}
        <div className="slider-wrapper">
        <div className='slider-image-container' style={{ backgroundImage: `url(${slides[currentIndex].image})` }}>
          {/* Other content within the container */}
          <div className="title text-align-center flex space-between white slider-content">
            <div className='slider-button' onClick={prevSlide}><h1>{'<'}</h1></div>
              <h1>{slides[currentIndex].quote}</h1>
            <div className='slider-button' onClick={nextSlide}><h1>{'>'}</h1></div>
          </div>
        </div>

        </div>

    </div>
  )
}

export default ImageSlider