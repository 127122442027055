
import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './Biodiversity.css'
import bio1 from '../../assets/bio/jalak.jpg'
import bio2 from '../../assets/bio/komodo.jpg'
import bio3 from '../../assets/bio/orang_utan.jpg'
import bio4 from '../../assets/bio/rafflesia.jpg'
import bio5 from '../../assets/bio/anggrekhitam.png'
import bio6 from '../../assets/bio/jalak.png'
const Biodiversity = () => {
    
    useEffect(() => {
        AOS.init({
            duration: 2000, 
            delay: 200,    
            once:true,
          });
        }, []);

  return (
    <div className='container'>
        <div className="biodiversity flex gap-5">
            <div className="biodiversity-content
            flex
             direction-column">
                <div className="title"  data-aos="fade-up" >
                    <h1>BIODIVERSITY</h1>
                </div>
                <div data-aos="fade-right">
                    <p>
                        Indonesia is renowned for its rich biodiversity, hosting over 25,000 species of flowering plants, a vast array of spore-bearing plants, and a multitude of reptiles, amphibians, freshwater fish, and marine fish. The country also boasts 400+ coral species, constituting about 75% of global coral diversity. Numerous unique species, including insects like Wallace’s Giant Bee and Queen Alexandra Bird Wing butterfly, are exclusive to Indonesia. The commitment to conservation is evident in Indonesia's 566 national parks, covering over 360,000 square kilometers, encompassing both terrestrial and marine protected areas.</p>
                </div>
                <div className='wrapper-biodesc-3 flex gap-5 flex-wrap'>
                    <div className="biodesc-3" data-aos="fade-right" data-aos-delay="0">
                        <h3>Flowering plants</h3>
                        <p>25.000++ species</p>
                    </div>
                    <div className="biodesc-3" data-aos="fade-right" data-aos-delay="200">
                        <h3>Mamals</h3>
                        <p>515++ species</p>
                    </div>
                    <div className="biodesc-3" data-aos="fade-right" data-aos-delay="400">
                        <h3>Spore bearing <br></br>plants</h3>
                        <p>300++ species</p>
                    </div>
                    <div className="biodesc-3" data-aos="fade-right" data-aos-delay="600">
                        <h3>Bird</h3>
                        <p>1592++ species</p>
                    </div>
                    <div className="biodesc-3" data-aos="fade-right" data-aos-delay="800">
                        <h3>Marine fish</h3>
                        <p>4900++ species</p>
                    </div>
                    <div className="biodesc-3" data-aos="fade-right" data-aos-delay="1000">
                        <h3>Corals</h3>
                        <p>400+  species</p>
                    </div>
                    
                </div>
            </div> 
            <div className="biodiversity-content
            flex
            align-item-center direction-column">
                    <div className="photo-gallery">
                        <div className="column">
                            <div className="photo">
                                <img src={bio1} alt="" />
                            </div>
                            <div className="photo">                               
                                <img src={bio3} alt="" />
                            </div>
                            
                            <div className="photo img-bottom">                               
                                <img src={bio5} alt="" />
                            </div>
                            


                        </div>
                        <div className="column pt-50">
                        <div className="photo">
                                
                                <img src={bio2} alt="" />
                            </div>
                            <div className="photo">
                                <img src={bio4}alt="" />
                            </div>
                            <div className="photo img-bottom">
                                <img src={bio6}alt="" />
                            </div>
                            


                        </div>
                        {/* <div className="photo-gallery">
                        <div className="column">
                            <div className="photo">
                                <img src={bio1} alt="" />
                            </div>
                            <div className="photo">
                                <img src={bio3}alt="" />
                            </div>
                            <div className="photo">
                                
                                <img src={bio5} alt="" />
                            </div>

                        </div>
                        </div> */}

 
                    </div>
            </div> 
        </div>

    </div>
  )
}

export default Biodiversity