


import Form from '../components/Form/Form'

const ContactUs = () => {

  return (
    
    <div className='container'>
        <div className="title text-align-center geography-text"  >
          <h1>CONTACT US</h1>
          <p>get connected with us</p>
        </div>
        <div className="flex align-item-center">
          <Form />  
        </div>
        

            
    </div>
  )
}

export default ContactUs