

const teamAssistants = [
    { 
        id: 1,
        image: require('../../assets/aboutus/prima.jpg'),
        fb: 'https://facebook.com/',
        wa: 'https://wa.me/1XXXXXXXXXX',
        name: 'Prima Herlim',
        position: 'Assistant Operations Manager',
        born: '23 yrs old, born in Bali, 2000',
        language: 'Bahasa, English',
        description: {
          list: [
            'Education: Bachelor Degree IT',
            '3 years working in tourism',
            'Works in Bali, Timor, Sumba, Flores, North Maluku, Ternate Tidore Isands, Banda',
         ] 
        },

    },        
    { 
        id: 2,
        image: require('../../assets/aboutus/eric.jpg'),
        
        fb: 'https://facebook.com/',
        wa: 'https://wa.me/1XXXXXXXXXX',
        name: 'Eric Hutchings',
        position: 'Marketing and Trip Design Manager',
        born: '1953, Edmonton, Canada',
        language: 'English',
        description: {
          list: [
            'married, five children',
            'Batchelor’s Degree – Biology',
            '20 year government natural resource management, aquaculture, aquaponics; 5 years environmental consulting, 10 years tourism sector.',
            'Interests: travel, history, cooking, culture, fishing.'
         ] 
        },

    },        
    { 
        id: 3,
        image: require('../../assets/aboutus/rani.jpg'),
        fb: 'https://facebook.com/',
        wa: 'https://wa.me/1XXXXXXXXXX',
        name: 'Rani Setiawanti',
        position: 'Coordinator & Marketing Support Assistant',
        born: 'Java, 1985',
        language: 'Bahasa, English',
        description: {
          list: [
            'Education: Diplomas in Tour Travel 2015, Accounting and Secretarial Services.)',
            'Bookings and logistic coordination, travel host.',
            'Country wide, also local focus on Bandung, Yogyakarta, Bali, Lombok.',
            'Interests: Travel, nature, local food, yoga'
         ] 
        },

    },        
            
 
];

export default teamAssistants;