

const teamElites = [
    { 
        id: 1,
        image: require('../../assets/aboutus/ali.jpg'),
        fb: 'https://facebook.com/',
        wa: 'https://wa.me/1XXXXXXXXXX',
        name: 'Ali Sehidun',
        position: 'Co-Founder',
        born: 'December 31, 1981, Nampar, West Flores',
        language: 'Indonesia',
        description: {
          list: [
            'Married, 3 children.',
            'Graduate -College of Tourism Academy in Makassar City, Sulawesi.',
            'Member of the Hotel and Restaurant Association of Indonesia, the HPI Guides Association and the ASITA Travel Agents Association',
            'Secretary, Labuan Bajo Tourism Association.'
         ] 
        },

    },        
    { 
        id: 2,
        image: require('../../assets/aboutus/randal.jpeg'),
        fb: 'https://facebook.com/',
        wa: 'https://wa.me/1XXXXXXXXXX',
        name: 'Randal Glaholt',
        position: 'Co-Founder',
        born: '1954, Calgary, Canada',
        language: 'English',
        description: {
          list: [
            'Graduate Degree Environmental Design (Environmental Science)',
            '30 years consulting, 10 years tourism',
            'Work and or travel in 37 countries',
            'Passionate about biodiversity conservation, ecological restoration, sustainable tourism and development, meeting new people, cross cultural experiences.',
            'Hobbies: hiking, snorkeling/diving, photography, fly fishing, racquet sports, golf, paddling, biking.'
         ] 
        },

    },        
            
 
];

export default teamElites;