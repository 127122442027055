

const teamDatas = [
    { 
        id: 1,
        image: require('../../assets/aboutus/udin-1.jpg'),
        fb: 'https://facebook.com/',
        wa: 'https://wa.me/1XXXXXXXXXX',
        name: 'Udin',
        born: 'Born at Borobudur heritage site, Java, 1969',
        language: 'Bahasa, Javanese, English, French, Italian',

        description: {
          list: [
            'Licensed guide for Borobudur Temple and Licensed National Tour Guide',
            'Began working as a professional tour guide in 1989 (28 yrs).',
            'Travel guide/host for Java, Bali, Lombok, Sulawesi, Flores',
            'Interests: History, bicycling, travel, adventure'
            
          ] 
        },

    },
    {
      id: 2,
      image: require('../../assets/aboutus/ito.jpg'),
      fb: 'https://facebook.com/',
      wa: 'https://wa.me/1XXXXXXXXXX',
      name: 'Ito',
      born: 'Born in Jakarta,1963',
      language: 'Bahasa, Javanese, Balinese, Sundanese, English, some Japanese and Chinese.',

      description: {
        list: [
          'Graduated from Law faculty, Atmajaya University, Jakarta',
          'Licensed guide, 20+ years experience',
          'Works in Bali, Java, East Nusa Tenggara (Flores, Sumba, East Timor), Kalimantan, Papua.',
          'Interests: travel, geography. history, table tennis, billiards, chess'
          
        ]
      }
    },
    {
      id: 3,
      image: require('../../assets/aboutus/dewa.jpg'),
      fb: 'https://facebook.com/',
      wa: 'https://wa.me/1XXXXXXXXXX',
      name: 'Dewa',
      born: 'Born in Bali 1974',
      language: 'Bahasa, Balinese, Bahassa English,',

      description: {
        list: [
          'Interests: Travel, yoga, meditation, well-being',
          'Licensed guide, working in tourism for 30 years.',
          'Bali specialist'
          
        ]
      }
    },
    {
      id: 4,
      image: require('../../assets/aboutus/ryan.jpg'),
      fb: 'https://facebook.com/',
      wa: 'https://wa.me/1XXXXXXXXXX',
      name: 'Ryan “Momod”',
      born: 'Native Dayak, born in Pangkalan Bun, Borneo 1967',
      language: 'Dayak, English, Javanese, Bahasa, Bornean Malay.',
  
      description: {
        list: [
          'Interests: Trekking, nature, self-improvement, badminton, travel.',
          '30 years in tourism sector, Former Secretary General Indonesian Travel Agent Association (2018-2022)',
          'Licensed guide, graduate Indonesia Tourism Academy',
          'In-depth knowledge of Kalimantan/Borneo, Java, Flores.'
          
        ]
      }
    },
    {
      id: 5,
      image: require('../../assets/aboutus/edwin-150x150.jpg'),
      fb: 'https://facebook.com/',
      wa: 'https://wa.me/1XXXXXXXXXX',
      name: 'Edwin',
      born: 'Born in Singapore, 1963',
      language: 'English',

      description: {
        list: [
          'Bachelor Degree – Marketing',
          'Singapore Licensed Tourist Guide (since 2006). 17 years experience.',
          'Actively developing and delivering Singapore Tourism Board-approved guide and sustainable tourism. Volunteer guide with Singapore Zoo (since 2000) and Singapore National Parks Board (since 2005)',
          'Focus on Singapore City and Cultural Tours',
  
          
        ]
      }
    },
    {
      id: 6,
      image: require('../../assets/aboutus/geoffrey-1.jpg'),
      fb: 'https://facebook.com/',
      wa: 'https://wa.me/1XXXXXXXXXX',
      name: 'Geoffrey',
      born: 'Born in Australia',
      language: 'English, Spanish, French, Dutch, Bahasa Indonesia and Bahasa Bali',
 
      description: {
        list: [
          'PhD Scripps Institution of Oceanography – Marine Biology & Oceanography',
          'Over 15 years marine, conservation, and tourism support in West Papua, Raja Ampat',
          'Expert knowledge Papua, Raja Ampat.',     
        ]
      }
    },
    {
      id: 7,
      image: require('../../assets/aboutus/gus.jpg'),
      fb: 'https://facebook.com/',
      wa: 'https://wa.me/1XXXXXXXXXX',
      name: 'Gus',
      born: '23 yrs old, born in Bali, 2000',
      language: 'Bahasa, English',
 
      description: {
        list: [
          '? years working in tourism',
          'Works in Nusa Penida, Bali,'
  
  
          
        ]
      }
    }
];

export default teamDatas;