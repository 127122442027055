import React, { useState,useEffect } from 'react';
import AOS from 'aos'
import 'aos/dist/aos.css'

import './Navbar.css'
import Logo from '../../assets/Logo.png'
const Navbar = () => {
  useEffect(()=>{
    AOS.init({duration:2000});
}, [])
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };


  return (
    <div className='navbar'>
      <div className="container flex align-item-center">
        <div className="container flex space-between align-item-center">
          <div className="logo">
            <img src={Logo} alt="" />
            
          </div>
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <div className={`navbar-links ${isMenuOpen ? 'open' : ''}`}>
            
              <div className="nav2 flex gap-5">
                  <a href="/" className='navigation'>Home</a>
                  <a href="/About Us" className='navigation'>About Us</a>
                  <a href="/ArtOfTravel" className='navigation'>Art of Travel</a>
                  <div className='navigation drop'>
                    Destinations    
                    <div className="dropdown">
                      <div className="destination-link">
                        <a href="/Destinations/1" className='navigation drop-navigation'>Flores Komodo</a>
                        <a href="/Destinations/2" className='navigation drop-navigation'>Bali</a>
                        <a href="/Destinations/3" className='navigation drop-navigation'>Sumatra</a>
                        <a href="/Destinations/4" className='navigation drop-navigation'>Jawa</a>
                        <a href="/Destinations/5" className='navigation drop-navigation'>Sulawesi</a>
                        <a href="/Destinations/6" className='navigation drop-navigation'>Lombok</a>
                        <a href="/Destinations/7" className='navigation drop-navigation'>Papua</a>
                        <a href="/Destinations/8" className='navigation drop-navigation'>Sumbawa</a>
                        <a href="/Destinations/9" className='navigation drop-navigation'>Kalimantan</a>
                        <a href="/Destinations/10" className='navigation drop-navigation'>Sumba</a>
                      </div>
                    </div>
                  </div>
                  <a href="" className='navigation'>Map</a>
                  <a href="" className='navigation'>Special Events</a>
                  <a href="/ContactUs" className='navigation'>Contact Us</a>
                </div>
                

          </div>
          <div className="nav3">
              
          </div>
        </div>

      </div>
    </div>
  )
}

export default Navbar