import React, { useState } from 'react';
import axios from 'axios';
import './Form.css'
const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Replace 'YOUR_FORMSPREE_ENDPOINT' with the actual endpoint you get from Formspree
      const response = await axios.post('https://formspree.io/f/xrgnzerk', formData);

      console.log(response.data);
    } catch (error) {
      console.error('Error submitting the form:', error);
    }
  };
  return (
    <div className="form">
        {/* <form onSubmit={handleSubmit}>
      <div className="form-container">
          <div>
            <label htmlFor="name">Name:</label>
            <input className='input-box' type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
            
          </div>
          <div>
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} />

          </div>
          <div>
            <label htmlFor="number">Number:</label>
            <input type="text" id="number" name="number" value={formData.number} onChange={handleChange} />

          </div>


      </div>
      <div className="form-container">

          <div>
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} />
          </div>
          <button type="submit">Submit</button>
      </div>
        </form> */}
      <form onSubmit={handleSubmit}> 
        <div className="form-container">
          <div className="form-up">
            <div class="form-name"> 
              <input placeholder="Full Name" type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
            </div>

            <div class="form-box">
              <input type="email" placeholder="Enter Your Email Address" id="email" name="email" value={formData.email} onChange={handleChange} />
            </div>

            <div class="form-number">
              <input type="text" placeholder="Enter Your Contact Number" id="number" name="number" value={formData.number} onChange={handleChange} />
            </div>
          </div>
          <div className="form-bot">
            <div class="form-msg">
              <textarea id="message" placeholder="Enter Your Message" name="message" value={formData.message} onChange={handleChange} />
            </div>

            <button type="submit">Submit</button>
          </div>
        </div>

      </form>
    </div>

  )
}

export default Form