
// flores
import bgFlores from '../../assets/flores/flores.jpg'
import descImageFlores from '../../assets/flores/culture.png'
import cardImageFlores1 from '../../assets/flores/kelimutumount.png'
import cardImageFlores2 from '../../assets/flores/liangbuacave.png'
import cardImageFlores3 from '../../assets/flores/komodonationalpark.jpg'
import cardImageFlores4 from '../../assets/flores/riung.png'


// bali
import bgBali from '../../assets/bali/bali.jpg'
import descImageBali from '../../assets/bali/barong.png'
import cardImageBali1 from '../../assets/bali/gwk.jpg'
import cardImageBali2 from '../../assets/bali/klingking.jpg'
import cardImageBali3 from '../../assets/bali/lempuyang.jpg'
import cardImageBali4 from '../../assets/bali/ulundanu.jpg'




// sumatra
import bgSumatra from '../../assets/sumatra/sumatrabg.jpg'
import descImageSumatra from '../../assets/sumatra/sumatraculture.jpg'
import cardImageSumatra1 from '../../assets/sumatra/bukitlawang.png'
import cardImageSumatra2 from '../../assets/sumatra/kerinciseblatnationalpark.jpg'
import cardImageSumatra3 from '../../assets/sumatra/tobalake.png'
import cardImageSumatra4 from '../../assets/sumatra/wehisland.png'

// java
import bgJava from '../../assets/java/javabg.jpg'
import descImageJava from '../../assets/java/javaculture.jpeg'
import cardImageJava1 from '../../assets/java/borobudur.jpg'
import cardImageJava2 from '../../assets/java/prambanan.jpg'
import cardImageJava3 from '../../assets/java/bromo.jpg'
import cardImageJava4 from '../../assets/java/ujungkulon.jpg'

// sulawesi
import bgSulawesi from '../../assets/sulawesi/sulawesibg.png'
import descImageSulawesi from '../../assets/sulawesi/sulawesiculture.jpg'
import cardImageSulawesi1 from '../../assets/sulawesi/toraja.jpg'
import cardImageSulawesi2 from '../../assets/sulawesi/bunaken.jpg'
import cardImageSulawesi3 from '../../assets/sulawesi/birabeach.jpg'
import cardImageSulawesi4 from '../../assets/sulawesi/bugisphinisi.jpg'

// lombok
import bgLombok from '../../assets/lombok/lombokbg.jpg'
import descImageLombok from '../../assets/lombok/lombokculture.jpg'
import cardImageLombok1 from '../../assets/lombok/rinjani.jpg'
import cardImageLombok2 from '../../assets/lombok/sasak.jpg'
import cardImageLombok3 from '../../assets/lombok/tanjungaan.jpg'
import cardImageLombok4 from '../../assets/lombok/kutabeach.jpg'

// papua
import bgPapua from '../../assets/papua/papuabg.jpg'
import descImagePapua from '../../assets/papua/papuaculture.jpg'
import cardImagePapua1 from '../../assets/papua/misool.jpg'
import cardImagePapua2 from '../../assets/papua/divingrajaampat.jpg'
import cardImagePapua3 from '../../assets/papua/arfakmountains.jpg'
import cardImagePapua4 from '../../assets/papua/lorentznationalpark.png'

// sumbawa
import bgSumbawa from '../../assets/sumbawa/sumbawabg.jpg'
import descImageSumbawa from '../../assets/sumbawa/sumbawaculture.png'
import cardImageSumbawa1 from '../../assets/sumbawa/tambora.jpg'
import cardImageSumbawa2 from '../../assets/sumbawa/moyoisland.jpg'
import cardImageSumbawa3 from '../../assets/sumbawa/kencanabeach.jpg'
import cardImageSumbawa4 from '../../assets/sumbawa/lakeypeaksurfing.jpg'

// kalimantan
import bgKalimantan from '../../assets/kalimantan/kalimantanbg.jpg'
import descImageKalimantan from '../../assets/kalimantan/kalimantanculture.jpg'
import cardImageKalimantan1 from '../../assets/kalimantan/pangkalanbun.jpg'
import cardImageKalimantan2 from '../../assets/kalimantan/floatingmarket.jpg'
import cardImageKalimantan3 from '../../assets/kalimantan/derawan.jpg'
import cardImageKalimantan4 from '../../assets/kalimantan/mahakamriver.jpg'

// sumba
import bgSumba from '../../assets/sumba/sumbabg.jpg'
import descImageSumba from '../../assets/sumba/sumbaculture.jpg'
import cardImageSumba1 from '../../assets/sumba/praijing.jpg'
import cardImageSumba2 from '../../assets/sumba/wairinding.jpg'
import cardImageSumba3 from '../../assets/sumba/pasola.jpg'
import cardImageSumba4 from '../../assets/sumba/ratenggaro.jpg'

const destinations = [
    { 
      id: 1,
      name: "Flores",
      bg:bgFlores,
      descImage:descImageFlores,
      desc:"Nestled within the Indonesian archipelago, Flores, the “Land of Flowers”,  is an island that unfolds like a captivating story of nature’s artistry, cultural diversity, and deep-rooted history. Stretching over 350 kilometers from west to east, Flores offers a topographical marvel shaped by volcanic forces and geological wonders. Its dramatic landscapes, unique biodiversity, and a tapestry of indigenous cultures make it a compelling destination for travelers seeking a rich and immersive experience.",
      Topography_and_Geology:"Flores is a land of contrasts, where towering volcanoes and rugged coastlines coexist harmoniously. The island's topography is shaped by volcanic activity, with several active and dormant volcanoes dotting its landscape. At the heart of Flores stands Mount Kelimutu, a geological masterpiece hosting three tri-colored crater lakes. These lakes, known as Tiwu Nuwa Muri Koo Fai, Tiwu Ata Polo, and Tiwu Ata Mbupu, mesmerize visitors with their ever-shifting hues, creating a surreal and enchanting spectacle. The unique coastal landscapes of West Flores has become popularly known as The Indonesian Rivier. Volcanic soil has nurtured fertile valleys, lush forests, and fertile agricultural land, making Flores an agricultural paradise. The island's interior is characterized by steep hills and valleys, while its coastlines offer pristine beaches and crystal-clear waters. Flores's unique geological features, including volcanic craters and coral formations, make it a natural wonderland for exploration.",
      Biodiversity:"Flores's isolation and diverse landscapes have given rise to unique and endemic biodiversity. One of its most iconic inhabitants is the Komodo dragon, the world's largest living lizard. These prehistoric creatures, found on Komodo and Rinca Islands within Komodo National Park, offer a glimpse into Earth's distant past. Watching these powerful reptiles in their natural habitat is a journey back in time.The marine biodiversity surrounding Flores is equally astounding. The coastal regions, proximate to Labuan Bajo, Riung and Maumere are adorned with pristine coral reefs teeming with a kaleidoscope of fish, graceful sea turtles, and majestic manta rays. Komodo National Park ( a UNESCO World Heritage site) near Labuan Bajo and  17 Islands Marine Park, a hidden gem near RIung, are home to secluded beaches and vibrant underwater worlds, making it a paradise for divers, snorkelers and beach seakers. Flores's terrestrial ecosystems are equally diverse, hosting a range of bird species, including the Flores crow and Flores hawk-eagle. The island's unique flora includes orchids, pandanus, and various medicinal plants. Its biodiversity-rich ecosystems serve as a haven for nature enthusiasts and conservationists alike.",
      Humman_History_and_Cultures:"Flores's human history is a tapestry of indigenous traditions and outside influences. One of its most significant archaeological discoveries occurred in the Liang Bua cave, where Homo floresiensis, or the Hobbit, was unearthed. These ancient hominins, characterized by their small stature and unique features, challenge conventional theories of human evolution, making Flores a crucial site for paleoanthropological research. The island's cultural diversity is a testament to its rich history. Indigenous cultures include the Manggarai people in the West, Ngada people in the central highlands, and Sikka people of the East each with their own centuries-old traditions, spiritual beliefs, and architecture. In traditional villages like We Rebo, Bena Wogo, and others, visitors can immerse themselves in this rich heritage. Portuguese influence, stemming from colonial times, left a lasting mark on Flores's cultural landscape. Towns like Larantuka in East Flores are known for their Easter Procession, a grand celebration that melds Portuguese Catholic traditions with local customs. This cultural fusion showcases the island's unique identity.",
      cards: [
        {
          cardImage: cardImageFlores1,
          cardTitle: "Kelimutu Mount"
        },
        {
          cardImage: cardImageFlores2,
          cardTitle: "Liangbua Cave"
        },
        {
          cardImage: cardImageFlores3,
          cardTitle: "Komodo National Park"
        },
        {
          cardImage: cardImageFlores4,
          cardTitle: "Riung Marine Park"
        }
        // Add more cards as needed
      ],


      list: [
        'Komodo National Park: Witness the awe - inspiring Komodo dragons, a staggering sunset fruit bat migration, and truly giant manta rays. Biodiversity abounds above and below the water.',
        'Riung 17 Islands Marine Park: Dive into vibrant underwater worlds, swim with manta rays, and enjoy secluded beaches.',
        'Riung 17 Islands Marine Park: Dive into vibrant underwater worlds, swim with manta rays, and enjoy secluded beaches.',
        'Spider - web rice fields: Check out the amazing rice cultivation near Ruteng in West Flores:',
        'Liang Bua Cave: Explore the site of the Homo floresiensis discovery, delving into paleoanthropological history.',
        'Mount Kelimutu: Embark on a sunrise hike to witness the magical tri - colored lakes and the island’s geological wonders.',
        'Traditional Villages: Immerse yourself in indigenous cultures by visiting traditional villages like Wae Rebo, Bena, Wogo.',
        'Easter Procession in Larantuka:Experience the unique blend of Portuguese Catholic traditions and local customs during this grand cultural event.',
        'Caci Whip-Fighting Dance:Witness the cultural richness of Flores throughthis mesmerizing ritual.',
        'Culinary Exploration:Savor the flavors of Flores, sampling traditional disheslike ‘ikan bakar’ (grilled fish) and ‘lomok-lomok’ (a local delicacy).',
        'Fishing for Giant Trevally: opportunities abound for tangling with large Giant Trevally found where you find them along much of Flores coastal margin.',   
      ]
    },
    { 
      id: 2,
      name: "Bali",
      bg:bgBali,
      descImage:descImageBali,
      desc:"Bali, the famed Island of the Gods, with its varied landscape of hills and mountains, rugged coastlines and sandy beaches, lush rice terraces, and volcanic hillsides all providing a picturesque backdrop to its colorful, deeply spiritual, and unique culture stakes a serious claim to be paradise on earth.",
      Topography_and_Geology:"Bali's topography is a mesmerizing blend of dramatic landscapes, ranging from towering volcanic peaks to pristine beaches. At the heart of the island stands the mighty Mount Agung, an active stratovolcano that looms at 3,031 meters (9,944 feet) above sea level. This geological wonder not only shapes the island's topography but also holds significant cultural and spiritual importance to the Balinese people. The island's fertile soil owes its richness to volcanic activity, making Bali an agricultural haven. Verdant terraced rice fields, swaying coconut palms, and tropical forests dominate the lowlands, while the coastline is fringed with picturesque beaches, some with pristine white sands, others with striking black volcanic sands. Bali's geological diversity is further enhanced by its proximity to the Wallace Line, a biogeographical boundary that separates the flora and fauna of Asia and Australia. As a result, the island boasts unique geological features and a rich biodiversity that make it a haven for nature enthusiasts.",
      Biodiversity:"Bali's biodiversity is a testament to the island's unique ecological positioning. Its tropical climate and varied landscapes support a wide array of plant and animal species. The lush rainforests are home to rare and endemic creatures like the Bali starling, a critically endangered bird found nowhere else in the world. The coral reefs surrounding the island offer a kaleidoscope of marine life, attracting divers and snorkelers from across the globe. In addition to its terrestrial and marine ecosystems, Bali's rivers and wetlands provide habitats for diverse species, including exotic birds and amphibians. The Bali myna, also known as the Bali starling, is perhaps the most famous of these endemic species and is considered a symbol of conservation efforts on the island. Bali's rich biodiversity is not only a source of wonder but also integral to its culture. The Balinese people have a deep spiritual connection with nature, and this reverence for the environment is evident in their daily rituals, art, and festivals.",
      Humman_History_and_Cultures:"The history of Bali is as colorful as its landscapes. Archaeological evidence suggests human habitation on the island dating back to the early Holocene period. Over the centuries, Bali has witnessed the rise and fall of various kingdoms, from the Majapahit Empire to Dutch colonial rule, each leaving its mark on the island's culture and architecture. One of the most striking aspects of Bali's culture is its Hindu-Balinese religion, a unique blend of Hinduism, animism, and Buddhism. The island is adorned with thousands of temples, from the iconic Uluwatu Temple perched on cliffs to the serene Besakih Temple on the slopes of Mount Agung. These temples serve as centers of worship and are integral to the island's cultural identity. Balinese culture is also celebrated through traditional dances and arts, such as the graceful Legong dance and the vibrant Barong dance. Visitors can witness these performances at local villages and cultural centers, gaining insight into the island's rich artistic heritage.",
      cards: [
        {
          cardImage: cardImageBali1,
          cardTitle: "Garuda Wisnu Kencana"
        },
        {
          cardImage: cardImageBali2,
          cardTitle: "Pantai Klingking"
        },
        {
          cardImage: cardImageBali3,
          cardTitle: "Lempuyang Temple"
        },
        {
          cardImage: cardImageBali4,
          cardTitle: "Ulundanu Temple"
        }
        // Add more cards as needed
      ],


      list: [
        'Uluwatu Temple: Witness a mesmerizing Kecak dance performance against the backdrop of the Uluwatu Temple, perched on cliffs overlooking the Indian Ocean.',
        'Mount Batur Sunrise Trek: Embark on a predawn trek to the summit of Mount Batur and watch the sunrise over the caldera, a truly awe-inspiring experience.',
        'Ubud’s Artistic Enclave: Explore the art galleries, museums, and craft villages in Ubud, where you can witness Balinese artistry up close.',
        'Watersports: Whether a verteran or a first time water-sports enthusiast, Bali is a great place to partake in surfing, kite-boarding, SCUBA diving and Jet Skiing (try the Nusa Penida Express!).',
        'Sacred Monkey Forest Sanctuary: Interact with playful macaques in the lush Monkey Forest of Ubud while exploring ancient temple ruins.',
        'Tegallalang Rice Terraces: Marvel at the intricate and verdant rice terraces in Tegallalang, a testament to the Balinese agricultural heritage.',
        'Bali’s Underwater World: Dive or snorkel in the crystal-clear waters of Nusa Penida to encounter manta rays, vibrant coral gardens, and elusive ocean sunfish (Mola Mola).',
        'Balinese Cuisine: Delight in a culinary journey through Balinese cuisine, savoring dishes like babi guling (suckling pig) and nasi goreng (fried rice) at upscale restaurants or traditional warungs',
        'Water Temples of Bali: Explore the mystical water temples such as Tirta Empul, where you can partake in a traditional purification ritual.',
        'Luxury Beach Resorts: Enjoy the opulent comforts of Bali’s luxury beachfront resorts, offering world-class spa treatments, private villas, and gourmet dining.',
        'Bali’s Wellness Retreats: Indulge in rejuvenating spa treatments, yoga, and meditation at one of Bali’s renowned wellness retreats, set in tranquil natural surroundings.', 
      ]
    },
    { 
      id: 3,
      name: "Sumatra",
      bg:bgSumatra,
      descImage:descImageSumatra,
      desc:"Sumatra, the sixth - largest island in the world, is a mesmerizing destination that beckons adventurous travelers with its stunning topography, rich biodiversity, and diverse human history and cultures. This descriptive summary aims toprovide insights into Sumatra’s topography and geology, its remarkable biodiversity, and the tapestry of human history and cultures that make Sumatra a unique and captivating destination. We will also conclude with a curated list of up to 10 special sites and experiences that would enthrall a well-educated, wealthy, open-minded, and adventurous visitor",
      Topography_and_Geology:"Sumatra's topography is nothing short of breathtaking, offering a diverse range of landscapes that range from towering volcanic peaks to dense rainforests and pristine coastlines. At the heart of the island lies the Barisan Mountains, a volcanic range that stretches from north to south. This range includes several active volcanoes, with Mount Kerinci being the highest peak in Indonesia at 3,805 meters (12,484 feet) above sea level. The island's geological history is shaped by tectonic forces, which have given rise to the Great Sumatran Fault. This geological feature has made Sumatra prone to earthquakes and tsunamis, some of which have left significant impacts on the island's landscape and communities. Sumatra's unique geology contributes to its lush and fertile soil, making it an ideal place for agriculture. The rich volcanic soil supports the cultivation of a wide variety of crops, including coffee, rubber, and spices, which are vital to the island's economy.",
      Biodiversity:"Sumatra's biodiversity is a treasure trove of natural wonders. The island's lush rainforests are home to some of the world's most critically endangered species, including the Sumatran tiger, Sumatran orangutan, and Sumatran rhinoceros. These magnificent creatures are at the heart of conservation efforts to protect their fragile habitats. Among Sumatra’s unique plant species are: the corpse flower (Rafflesia arnoldii), the world’s largest individual flower; and the Titan Arum (Amorphophallus titanium), the world’s largest unbranched flower, known to reach a height of up to 6 meters. The island's rivers and lakes are teeming with diverse aquatic life, while its coastal areas are renowned for coral reefs that harbor an astonishing variety of marine species. Sumatra's vast biodiversity is a testament to its ecological significance and the need for preservation.Tropical rainforests on Sumatra, such as those found in Gunung Leuser National Park, offer travelers a glimpse into the heart of this vibrant ecosystem. Trekking through the dense jungles and witnessing the unique wildlife in their natural habitat is a once-in-a-lifetime experience.",
      Humman_History_and_Cultures:"Sumatra's human history is as diverse as its landscapes. Over the centuries, the island has been inhabited by various ethnic groups and has played a role in regional trade and culture. The Minangkabau people of West Sumatra, known for their unique architecture and matrilineal culture, are a prime example of Sumatra's cultural diversity. The island's history also includes the influence of various empires, such as the Srivijaya and Majapahit kingdoms, which left their mark on Sumatran art, architecture, and religion. Islam, introduced by traders and travelers, has played a significant role in shaping the island's religious landscape. Traditional ceremonies and festivals, such as the Tabuik Festival in Pariaman, showcase the rich cultural heritage of Sumatra. Visitors can witness traditional dances, rituals, and crafts that have been passed down through generations.",
      cards: [
        {
          cardImage: cardImageSumatra1,
          cardTitle: "Bukit Lawang"
        },
        {
          cardImage: cardImageSumatra2,
          cardTitle: "Kerinci Seblat National Park"
        },
        {
          cardImage: cardImageSumatra3,
          cardTitle: "Toba Lake"
        },
        {
          cardImage: cardImageSumatra4,
          cardTitle: "Weh Island"
        }
        // Add more cards as needed
      ],


      list: [
        'Orangutan Trekking in Gunung Leuser National Park:Embark on a guided trek through the rainforests of North Sumatra to witness Sumatran orangutans and other unique wildlife.',
        'Volcano Climbing: Scale the active volcanoes of Sumatra, including Mount Kerinci, for exhilarating views and a sense of accomplishment.',
        'Lake Toba: Visit the massive Lake Toba, formed by a volcanic eruption, and explore the cultural heritage of the Batak people on Samosir island.',
        'Bukit Lawang: Experience the thrill of river tubing along the pristine Bohorok River in Bukit Lawang and observe semi-wild orangutans.',
        'Traditional Minangkabau Culture:Immerse yourself in the unique matrilineal culture of the Minangkabau people in West Sumatra, including tasting their delicious cuisine.',
        'Kerinci Seblat National Park: Explore the largest national park in Sumatra, known for its stunning landscapes, diverse wildlife, and challenging treks.',
        'Aceh’s Cultural Heritage: Discover the cultural richness of Aceh, with its distinctive architecture, cuisine, and traditional arts.',
        'Medan’s Culinary Scene: Savor the diverse flavors of Sumatran cuisine in Medan, known for its delectable street food.',
        'Weh Island: Dive or snorkel in the crystal-clear waters surrounding Weh Island, which boasts vibrant coral reefs and a relaxed atmosphere.',
        'Kubu Beach:Unwind on the secluded and pristine beaches of Kubu, away from the crowds, for a true island paradise experience.',

      ]
    },
    { 
      id: 4, 
      name: "Java",
      bg:bgJava,
      descImage:descImageJava,
      desc:"Java, the most populous island in Indonesia, is a land of captivating contrasts. From its dramatic topography and geological wonders to its rich biodiversity, intricate human history, and diverse cultures, Java offers a rich tapestry of experiences with something to interest most any traveller.",
      Topography_and_Geology:"Java's topography is a dynamic blend of natural wonders and geographical diversity. The island is dominated by volcanic landscapes, and it is home to some of Indonesia's most iconic volcanoes. The Java Trench, part of the Pacific Ring of Fire, runs along the southern coast of the island and is responsible for Java's volcanic activity. One of the most famous volcanoes is Mount Merapi, an active stratovolcano known for its frequent eruptions. Mount Bromo, with its otherworldly caldera and stunning sunrises, is another must-visit volcanic destination on the island.The volcanic activity has also endowed Java with fertile soil, making it an agricultural powerhouse. Lush, terraced rice fields and tea plantations grace the landscape, providing not only sustenance but also breathtaking scenery.",
      Biodiversity:"Java's biodiversity is as diverse as its landscapes. The island's tropical climate and varied terrain support a wide array of flora and fauna. However, due to rapid urbanization and population growth, Java's natural ecosystems are increasingly under threat. While the island may not boast the same level of biodiversity as some other Indonesian islands, it is still home to a variety of unique species. Java is known for its Javan hawk-eagle, Javan rhinoceros (one of the world's most critically endangered mammals), and Javan slow loris, among others. The Ujung Kulon National Park, a UNESCO World Heritage Site, protects the habitat of the Javan rhinoceros and offers a glimpse into the island's natural beauty. Java's coastal areas are also rich in marine life, making it an ideal destination for diving and snorkeling. The coral reefs surrounding the island host a diverse range of underwater creatures and vibrant coral formations.",
      Humman_History_and_Cultures:"Java's human history dates back thousands of years. It has been inhabited by various ethnic groups, each contributing to the island's cultural mosaic. The Javanese people, who make up the majority of the population, have a rich cultural heritage that encompasses art, music, dance, and language. Java has played a significant role in Indonesia's history. The island was the center of powerful kingdoms, including the Mataram Sultanate, which left behind impressive temples like Prambanan and Borobudur. These UNESCO World Heritage Sites showcase Java's architectural and artistic brilliance, and they are a testament to the island's historical significance. The island has also been influenced by various religions, including Hinduism and Buddhism during the Srivijaya and Majapahit eras, and later Islam, which is the predominant religion today. This religious diversity is reflected in the architecture and traditions found across Java. Java's culture is further enriched by its traditional ceremonies, such as the Wayang Kulit shadow puppetry and the gamelan orchestra, which provide insight into the island's artistic heritage",
      cards: [
        {
          cardImage: cardImageJava1,
          cardTitle: "Borobudur Temple"
        },
        {
          cardImage: cardImageJava2,
          cardTitle: "Prambanan Temple"
        },
        {
          cardImage: cardImageJava3,
          cardTitle: "Bromo National Park"
        },
        {
          cardImage: cardImageJava4,
          cardTitle: "Ujung Kulon National Park"
        }
        // Add more cards as needed
      ],
      list: [
        'Borobudur Temple: Explore the magnificent Borobudur, the worlds largest Buddhist temple, and watch the sunrise from its serene terraces.',
        'Prambanan Temple: Visit the intricately carved Prambanan, a Hindu temple complex that rivals Borobudur in its architectural grandeur.',
        'Mount Bromo Trek: Embark on a trek to the summit of Mount Bromo to witness the surreal sunrise over the otherworldly landscape and or enjoy an all day tour of its massive caldera',
        'Jakarta Shopping, Dining & Entertainment: One day and multi day explorations of Jakarta to see glimpses of its colonial past, check out its landmarks, sample some of the very best in Indonesian and international cuisine, and experience its vibrant night life. Cap it all off with a midnight durian snack!',
        'Ujung Kulon National Park: Discover the unique ecosystems of Ujung Kulon, home to the Javan rhinoceros, while trekking through lush rainforests and pristine beaches.',
        'Dieng Plateau: Explore the highlands of Dieng, known for its volcanic activity, ancient temples, and stunning landscapes.',
        'Kawah Putih: Visit the otherworldly Kawah Putih, a turquoise crater lake surrounded by a volcanic landscape.',
        'Surfing in Batu Karas: Ride the waves in Batu Karas, a laid-back coastal village popular among surfers.',

      ]
     },

    { id: 5, 
      name: "Sulawesi",
      bg:bgSulawesi,
      descImage:descImageSulawesi,
      desc:"Sulawesi, often referred to as the Orchid Island due to its distinctive shape resembling an orchid or a K when rotated, is a captivating destination in the Indonesian archipelago.",
      Topography_and_Geology:"Sulawesi's topography is nothing short of breathtaking. The island is renowned for its unusual and striking shape, which divides it into four distinct peninsulas: the northern Minahasa Peninsula, the northeastern Minahasa Peninsula, the southern Peninsula, and the southwestern Peninsula. These divisions create a remarkable landscape of mountains, high plateaus, deep valleys, and pristine coastlines. Geologically, Sulawesi is a complex and fascinating region. It lies at the convergence of multiple tectonic plates, leading to a series of geological phenomena. The island is dotted with numerous active volcanoes, including Mount Soputan and Mount Lokon, which add to its dramatic and dynamic topography. The land is also scarred by deep fault lines, most notably the Palu-Koro Fault, which has been responsible for significant earthquakes and tsunamis in the past. One of the most intriguing geological features of Sulawesi is its karst landscapes. The limestone formations, such as those found in Maros-Pangkep, are characterized by towering limestone cliffs, hidden caves, and underground rivers, creating a surreal and captivating terrain",
      Biodiversity:"Sulawesi is a biodiversity hotspot, boasting an extraordinary variety of flora and fauna. Its isolation from neighboring islands has led to the evolution of numerous endemic species found nowhere else on Earth. The island's tropical climate, diverse ecosystems, and unique geology contribute to this rich biodiversity. One of the most iconic and critically endangered species found on Sulawesi is the crested black macaque, known locally as the ‘yaki.’ The island is also home to the unusual babirusa, a deer-pig hybrid, and the tiny tarsier, one of the world's smallest primates. Marine biodiversity is equally impressive, with colorful coral reefs and an array of marine life surrounding the island. Tangkoko National Park, in the northern Minahasa Peninsula, is a haven for wildlife enthusiasts. It offers a chance to observe crested black macaques, tarsiers, and various bird species in their natural habitat. Beneath the waves, Bunaken National Marine Park showcases vibrant coral gardens, making it a diving and snorkeling paradise.",
      Humman_History_and_Cultures:"The human history of Sulawesi is as diverse as its landscapes. The island has been inhabited by various ethnic groups, each with its own distinct cultures and traditions. The Minahasan people in the north, the Torajans in the central highlands, and the Bugis and Makassar in the south all contribute to the cultural tapestry of Sulawesi. The Torajan culture is particularly renowned for its unique funeral ceremonies, where the deceased are not considered truly ‘dead’ until elaborate funeral rituals have been performed. These ceremonies involve traditional music, dancing, and the sacrifice of water buffaloes, making them a fascinating cultural spectacle. In the southern regions, the Bugis people have a seafaring heritage and are known for their beautiful handcrafted wooden ships, known as ‘phinisi.’ These ships have been traditionally used for trading and fishing and are an integral part of Bugis culture. Islam is the predominant religion in Sulawesi, but the island's indigenous animistic beliefs and customs continue to influence the local culture. The coexistence of these different belief systems adds depth and diversity to the island's cultural fabric.",
      cards: [
        {
          cardImage: cardImageSulawesi1,
          cardTitle: "Tana Toraja"
        },
        {
          cardImage: cardImageSulawesi2,
          cardTitle: "Bunaken National Marine Park"
        },
        {
          cardImage: cardImageSulawesi3,
          cardTitle: "Bira Beach"
        },
        {
          cardImage: cardImageSulawesi4,
          cardTitle: "Bugis Phinisi"
        }
        // Add more cards as needed
      ],


      list: [
        'Tana Toraja: Immerse yourself in Torajan culture by attending a traditional funeral ceremony and exploring unique burial sites carved into cliffs and caves.',
        'Bunaken National Marine Park: Dive into the crystal-clear waters of Bunaken to witness vibrant coral reefs teeming with marine life.',
        'Tangkoko National Park: Embark on a wildlife adventure to spot crested black macaques, tarsiers, and exotic bird species in their natural habitat.',
        'Bira Beach: Relax on the pristine white-sand beaches of Bira and witness traditional shipbuilding in the Bugis villages.',
        'The Art of Traditional Ship Building: Visit coastal villages with a long tradition of wooden ship building and take a hand at helping build a giant Phinisi',
        'Selayar Island: Escape to the secluded Selayar Island for pristine beaches, snorkeling, and a glimpse of traditional island life.',
        'Bugis Phinisi Cruise: Embark on a luxury cruise on a Bugis phinisi boat, exploring remote islands and experiencing the art of traditional sailing.',
        'Maros-Pangkep Karst: Explore the surreal karst landscapes with limestone cliffs, hidden caves, and underground rivers.',

      ]
    },
    { id: 6, 
      name: "Lombok",
      bg:bgLombok,
      descImage:descImageLombok,
      desc:"Lombok, an enchanting island located in the Indonesian archipelago, known for its pristine beaches, lush landscapes, and vibrant culture. ",
      Topography_and_Geology:"Lombok's topography is a stunning blend of dramatic landscapes and geological wonders. The island is characterized by rugged terrain, with its highest peak being Mount Rinjani, an active stratovolcano standing at 3,726 meters (12,224 feet) above sea level. Rinjani's caldera, known as Segara Anak, contains a stunning crescent-shaped lake and a smaller volcanic cone called Barujari, which frequently emits smoke and gas, creating a surreal backdrop. The island's geology is influenced by its location within the Pacific Ring of Fire, resulting in a series of volcanic formations and seismic activity. Lombok's southern coastline is dotted with white sandy beaches and turquoise waters, while its northern coast features dramatic cliffs and hidden coves. Lombok is also known for its fertile soil, which supports a range of agricultural activities, including the cultivation of rice, tobacco, coffee, and spices. The volcanic soil enriches the island's landscapes and sustains its agriculture.",
      Biodiversity:"Lombok may be a relatively small island, but it harbors a diverse range of flora and fauna. The island's tropical climate and varied ecosystems provide a habitat for unique species, making it a haven for nature enthusiasts. The lush rainforests of Lombok are home to a variety of wildlife, including macaques, deer, and an array of bird species. The marine biodiversity is equally impressive, with colorful coral reefs teeming with fish, dolphins, and sea turtles. Gili Islands, a group of three small islands off Lombok's northwest coast, offer excellent diving and snorkeling opportunities to witness this underwater paradise. Lombok's biodiversity extends beyond its land and marine life. The island is known for its production of pearls, with pearl farms dotting the coastline. These pearls are highly regarded for their quality and luster.",
      Humman_History_and_Cultures:"Lombok has a rich human history shaped by various cultures and influences. The indigenous Sasak people, who make up the majority of Lombok's population, have a unique culture and language. The Sasak culture is characterized by traditional music, dance, and handicrafts. Visitors can explore traditional Sasak villages, such as Sade and Rambitan, to gain insights into their way of life. Lombok's history also includes the influence of neighboring cultures. The Balinese, who inhabit the western part of the island, have left their mark on Lombok's art, architecture, and religion. Balinese Hinduism coexists with Islam, the predominant religion on the island, creating a harmonious blend of traditions. The island's cultural diversity is celebrated through various festivals and ceremonies, such as the Bau Nyale festival, where thousands gather to catch sea worms during a unique natural phenomenon. Lombok's vibrant culture is deeply connected to its natural surroundings and is evident in its rituals and daily life.",
      cards: [
        {
          cardImage: cardImageLombok1,
          cardTitle: "Mount Rinjani"
        },
        {
          cardImage: cardImageLombok2,
          cardTitle: "Sasak Cultural"
        },
        {
          cardImage: cardImageLombok3,
          cardTitle: "Tanjung Aan Beach"
        },
        {
          cardImage: cardImageLombok4,
          cardTitle: "Kuta Beach"
        }
        // Add more cards as needed
      ],


      list: [
        'Mount Rinjani Trek: Embark on a challenging trek to the summit of Mount Rinjani for breathtaking panoramic views and a chance to soak in the volcanic hot springs at Segara Anak Lake.',
        'Gili Islands: Visit the Gili Islands (Gili Trawangan, Gili Meno, and Gili Air) for pristine beaches, snorkeling, diving, and a laid-back island atmosphere.',
        'Sasak Cultural Experience: Explore traditional Sasak villages, interact with locals, and witness traditional weaving and pottery-making.',
        'Tanjung Aan Beach: Relax on the unique, crescent-shaped Tanjung Aan Beach, known for its stunning white sands and turquoise waters.',
        'Kuta Beach: Discover the natural beauty and world-class surfing spots at Kuta Beach on the southern coast.',
        'Selong Belanak: Surf the gentle waves at Selong Belanak Beach, perfect for both beginners and experienced surfers.',
        'Pearl Farm Tour: Visit a pearl farm and learn about the process of pearl cultivation, and shop for exquisite pearl jewelry.',
        'Traditional Sasak Cooking Class: Learn to prepare traditional Sasak dishes and savor the flavors of Lombok cuisine.',

      ]},

    { id: 7, 
      name: "Papua",
      bg:bgPapua,
      descImage:descImagePapua,
      desc:"Raja Ampat is located off the Northwest corner of Papua’s Bird’s Head Peninsula in the easternmost part of Indonesia and is among the most captivating and remote destinations on the planet. ",
      Topography_and_Geology:"Raja Ampat, which translates to ‘Four Kings,’ is an archipelago consisting of over 1,500 islands, islets, and cays. This region's topography is a stunning testament to the forces of nature. The islands are characterized by limestone karst formations, mushroom-shaped islets, and dramatic sea cliffs that plunge into the crystal-clear waters. These geological wonders create a surreal and captivating backdrop for explorers and adventurers. The islands of Raja Ampat are surrounded by pristine coral reefs, forming a marine paradise known for its exceptional underwater landscapes. The region's unique geology has led to the development of some of the most biodiverse marine ecosystems on Earth. Papua, the larger province encompassing Raja Ampat, is equally remarkable in its geological diversity. The province includes extensive mountain ranges, including the towering Jayawijaya Range. This range is home to the highest peak in Indonesia, Puncak Jaya (Carstensz Pyramid), and the third-highest of the Seven Summits. Papua's rugged landscapes, dense rainforests, and powerful rivers contribute to its rich geodiversity. The region is also known for its unique flora, including the famous Rafflesia arnoldii, the world's largest flower, and exotic bird species such as the birds of paradise.",
      Biodiversity:"Raja Ampat and Papua are synonymous with extraordinary biodiversity. The waters surrounding Raja Ampat are often referred to as the ‘Amazon of the Seas’ due to their unparalleled marine diversity. The region is a global epicenter for coral species, with over 75% of the world's known coral species found here. The vibrant coral reefs provide a habitat for an astounding array of marine life, from colorful reef fish to graceful manta rays and majestic whale sharks. The islands of Raja Ampat are also vital for marine conservation efforts. Misool Eco Resort, for instance, actively contributes to the preservation of marine biodiversity and sustainable tourism practices. Papua's terrestrial biodiversity is equally astonishing. The dense rainforests are inhabited by unique and endemic species, including tree kangaroos, cassowaries, and various birds of paradise. Lorentz National Park, a UNESCO World Heritage Site that covers part of Papua's highlands, protects an immense range of ecosystems, from glaciers to tropical rainforests. Both Raja Ampat and Papua are critical for global biodiversity conservation, and the preservation of these environments is essential for future generations. Your travels to all of Indonesia’s parks, where well managed, can be a significant force in promoting local conservation efforts.",
      Humman_History_and_Cultures:"The history of Papua is marked by its isolation and the richness of its indigenous cultures. The region has been inhabited for tens of thousands of years by various indigenous groups, including the Dani, Asmat, and Papuans. These communities have developed unique languages, traditions, and art forms that reflect their deep connection to the land and environment. Papua's cultural heritage is particularly celebrated through its art, including intricate wood carvings, traditional musical instruments, and vibrant woven fabrics. The Asmat people, known for their stunning woodcarvings, are one of Papua's most renowned indigenous groups. Visitors can explore local villages to witness these living traditions. In recent history, Papua has faced challenges related to governance, resource extraction, and political autonomy. The province remains an important center for discussions about indigenous rights, land ownership, and environmental conservation.",
      cards: [
        {
          cardImage: cardImagePapua1,
          cardTitle: "Misool"
        },
        {
          cardImage: cardImagePapua2,
          cardTitle: "Diving Raja Ampat"
        },
        {
          cardImage: cardImagePapua3,
          cardTitle: "Arfak Mountains"
        },
        {
          cardImage: cardImagePapua4,
          cardTitle: "Lorentz National Park"
        }
        // Add more cards as needed
      ],


      list: [
        'Snorkeling and Diving in Raja Ampat: Explore the worlds richest coral reefs, dive with manta rays, and swim alongside whale sharks in the heart of marine biodiversity.',
        'Raja Ampat Liveaboard Cruises: Explore remote islands and underwater wonders aboard a luxury liveaboard yacht.',
        'Misool Eco Resort: Stay at Misool Eco Resort in Raja Ampat for a luxurious eco-friendly experience that supports marine conservation.',
        'Trekking in Papua Highlands: Embark on challenging treks in Papua highlands, including the Carstensz Pyramid summit climb, offering stunning vistas of glaciers and alpine landscapes.',
        'Birdwatching in the Arfak Mountains: Witness the mesmerizing displays of birds of paradise in their natural habitat.',
        'Lorentz National Park Exploration: Discover the pristine wilderness of Lorentz National Park, a UNESCO World Heritage Site, and the stunning landscapes of the Sudirman Range.',
        'Village Stays: Engage in cultural exchanges by staying with indigenous communities in traditional villages.',
        'Traditional Art and Culture: Attend local festivals, visit art galleries, and experience traditional ceremonies to immerse yourself in Papua diverse cultural heritage.',

      ] },

    { id: 8, 
      name: "Sumbawa", 
      bg:bgSumbawa,
      descImage:descImageSumbawa,
      desc:"Sumbawa, a lesser-known Indonesian gem nestled in the Indonesian archipelago, offers another mesmerizing blend of diverse topography, unique biodiversity, and a rich tapestry of human history and cultures. ",
      Topography_and_Geology:"Sumbawa's topography is a symphony of varied landscapes, featuring everything from dramatic mountains to pristine beaches. The island is part of the Lesser Sunda Islands and is nestled between Lombok to the west and Komodo Island to the east. The towering Mount Tambora is one of Sumbawa's most iconic geological features. It gained worldwide notoriety in 1815 when it erupted in the most powerful volcanic explosion in recorded history, launching 100 cubic kilometers of ash into the upper atmosphere, causing 1816, in many parts of the world, to be the ‘year without summer’. In addition to its volcanic peaks, Sumbawa is endowed with lush forests, savannas, and rolling hills. The island's soil is fertile, supporting the cultivation of various crops, including rice, corn, coffee, and cacao. The Wawaraba rice terraces are a testament to the island's agricultural heritage. Sumbawa's geology and diverse topography provide the perfect backdrop for exploration, whether trekking through the rugged terrain, venturing into volcanic craters, or relaxing on pristine beaches.",
      Biodiversity:"Despite its relatively small size, Sumbawa is home to a remarkable range of biodiversity. The island's unique position within the Indonesian archipelago has given rise to distinct flora and fauna. The terrestrial ecosystems of Sumbawa are inhabited by diverse wildlife, including macaques, deer, and a variety of bird species. The island's coastal areas and coral reefs are equally rich in marine life, attracting divers and snorkelers eager to explore the underwater wonders. The Sumbawa Strait, which separates Sumbawa from Komodo Island, is known for strong currents that bring nutrient-rich waters, resulting in an abundance of marine species. Divers can encounter vibrant coral gardens, schools of fish, and the occasional encounter with manta rays and sharks. The island's unique biodiversity extends to its flora, including exotic orchids and unique medicinal plants used in traditional herbal remedies.",
      Humman_History_and_Cultures:"Sumbawa's human history is steeped in a blend of indigenous cultures and historical influences. The indigenous people of Sumbawa, such as the Sumbawanese and Bimanese, have a rich cultural heritage. Traditional music, dance, and art are integral parts of their identity. Islam, introduced through trade and interactions with neighboring islands, has a significant presence on Sumbawa. The island's culture is imbued with Islamic traditions, as seen in its architecture, festivals, and daily life. Sumbawa also played a role in Indonesia's struggle for independence from Dutch colonial rule. The island was a battleground during the Indonesian National Revolution, and remnants of that era can still be explored today. The people of Sumbawa are known for their friendliness and hospitality, making it a welcoming destination for travelers interested in immersing themselves in local cultures.",
      cards: [
        {
          cardImage: cardImageSumbawa1,
          cardTitle: "Tambora Mountain"
        },
        {
          cardImage: cardImageSumbawa2,
          cardTitle: "Moyo Island"
        },
        {
          cardImage: cardImageSumbawa3,
          cardTitle: "Kencana Beach"
        },
        {
          cardImage: cardImageSumbawa4,
          cardTitle: "Lakey Peak Surfing"
        }
        // Add more cards as needed
      ],


      list: [
        'Moyo Island: Explore Moyo Island, a pristine nature reserve featuring waterfalls, lush forests, and a variety of wildlife.',
        'Kencana Beach: Relax on the beautiful Kencana Beach and soak in the tranquil atmosphere, or try your hand at fishing with locals.',
        'Traditional Handicrafts: Shop for traditional handwoven textiles, pottery, and wood carvings at local markets and workshops.',
        'Sumbawa Besar Cultural Tour: Discover the cultural heritage of Sumbawa Besar, the islands largest town, with visits to traditional markets, palaces, and museums.',
        'Tambora Caldera Exploration: Hike or drive through the Tambora Caldera, which offers stunning views of the calderas interior and historical insights into the 1815 eruption.',
        'Sumbawa Coffee Tasting: Experience the islands unique coffee culture with tastings of Sumbawa coffee, known for its distinct flavor.',
        'Bima Traditional Village Visit: Immerse yourself in the culture of the Bimanese people with visits to their traditional villages and witnessing their daily life.',

      ]},

    { id: 9, 
      name: "Kalimantan", 
      bg:bgKalimantan,
      descImage:descImageKalimantan,
      desc:"Kalimantan, the Indonesian part of the island of Borneo, boasts remarkable biodiversity and associated indigenous cultures. ",
      Topography_and_Geology:"Kalimantan's topography is a diverse and stunning canvas, featuring a mosaic of landscapes that range from lush rainforests to rugged mountains and meandering rivers. The island is known for its pristine natural beauty, which is deeply intertwined with its geological history. A significant portion of Kalimantan's land is covered by dense tropical rainforests, contributing to its status as one of the world's last great wildernesses. The island is crisscrossed by countless rivers, including the mighty Kapuas River, one of the largest in Indonesia. The extensive river systems are a lifeline for the island's inhabitants and are often used as transportation routes through the interior. The geological history of Kalimantan is marked by ancient mountain ranges and volcanic activity. The Meratus Mountains in South Kalimantan and the Muller Mountain Range in East Kalimantan provide a rugged and picturesque backdrop for exploration. These landscapes offer numerous trekking and hiking opportunities, revealing stunning vistas of untouched wilderness.",
      Biodiversity:"Kalimantan is a biodiversity hotspot, renowned for its extraordinary range of flora and fauna. The island's diverse ecosystems, from lowland rainforests to montane forests and peat swamps, support a vast array of species, many of which are endemic and unique to the region. The rainforests of Kalimantan are home to iconic wildlife, including orangutans, proboscis monkeys, clouded leopards, and pygmy elephants. The island's rivers and waterways are inhabited by crocodiles, freshwater dolphins, and diverse fish species. Birdwatchers can delight in spotting the magnificent hornbills, kingfishers, and countless other avian species. Two of the most celebrated inhabitants of Kalimantan's rainforests are the Bornean orangutan (Pongo pygmaeus) one of three species, and Proboscis Monkey (Nasalis larvatus). Tanjung Puting National Park in South Kalimantan, serves as a sanctuary for both of  these marvelous primates.  Kalimantan's peat swamps are also ecologically significant, providing habitat for unique plant species and rare animals such as the Bornean peat swamp frog. The island's mangrove forests along its coastline further contribute to its rich biodiversity.",
      Humman_History_and_Cultures:"Kalimantan's human history is as varied as its landscapes. The island has been inhabited for thousands of years by indigenous Dayak tribes, each with its own distinct cultures, languages, and traditions. These communities have historically lived in harmony with the natural environment, relying on hunting, gathering, and shifting agriculture. The Dayak tribes are known for their intricate art, such as woodcarvings and beadwork, and their unique longhouses, which serve as communal dwellings. These longhouses, constructed using traditional methods, offer a glimpse into the indigenous way of life. Kalimantan's history also includes the influence of external powers, with various kingdoms and sultanates having left their mark on the region over the centuries. The influence of Islam can be seen in the architecture and customs of the coastal areas, while the indigenous Dayak cultures continue to thrive in the interior.",
      cards: [
        {
          cardImage: cardImageKalimantan1,
          cardTitle: "Pangkalan Bun"
        },
        {
          cardImage: cardImageKalimantan2,
          cardTitle: "Floating Market"
        },
        {
          cardImage: cardImageKalimantan3,
          cardTitle: "Derawan"
        },
        {
          cardImage: cardImageKalimantan4,
          cardTitle: "Mahakam River"
        }
        // Add more cards as needed
      ],


      list: [
        'Orangutan Sanctuary: Visit Tanjung Puting National Park to witness orangutans in their natural habitat and explore the parks riverine landscapes.',
        'Floating Markets: Experience the vibrant floating markets of Banjarmasin, where locals trade fruits, vegetables, and handicrafts from boats.',
        'Meratus Mountains Trek: Embark on a trek through the lush Meratus Mountains, encountering unique flora, fauna, and indigenous Dayak communities.',
        'Kapuas River Expedition: Take a river expedition along the Kapuas River to explore remote villages and witness traditional Dayak culture.',
        'Peat Swamp Adventure: Venture into the mysterious peat swamps of Kalimantan to discover unique plant and animal life.',
        'Derawan Islands: Explore the Derawan Islands, a pristine archipelago known for its stunning coral reefs and diverse marine life.',
        'Cultural Encounters: Engage in cultural exchanges with indigenous Dayak communities, learning about their traditions and way of life.',
        'Mahakam River Cruise: Embark on a river cruise along the Mahakam River, visiting traditional Dayak villages and experiencing their unique ceremonies and art.',

      ]},

    { id: 10, 
      name: "Sumba",
      bg:bgSumba,
      descImage:descImageSumba,
      desc:"Sumba, is among the lesser-known and less visited Indonesian islands nestled in the southeastern archipelago, despite it’s truly magnificent landscapes, beaches, surfing and remarkable cultures.",
      Topography_and_Geology:"Sumba's topography is a striking blend of contrasts. The island is characterized by rolling hills, picturesque savannas, and rugged cliffs that drop dramatically into the azure waters of the Indian Ocean. These landscapes create a captivating tapestry that sets Sumba apart from other Indonesian islands. One of the most distinctive geological features of Sumba is its megalithic traditions, which are still prevalent today. Enigmatic stone tombs and monuments, known as adat, dot the island and reflect the deep connection between the Sumbanese people and their land. These megaliths, often adorned with intricate carvings, are a testament to Sumba's rich history and traditions. The island's geology is shaped by a limestone base, resulting in unique natural formations such as limestone hills, hidden caves, and underground rivers. These geological wonders provide opportunities for exploration and adventure for those willing to delve into Sumba's hidden depths.",
      Biodiversity:"Sumba's biodiversity is a testament to the island's isolation and unique ecosystems. The combination of distinct flora and fauna, coupled with its remote location, makes the island a haven for nature enthusiasts. One of the most iconic and endangered species found on Sumba is the Sumba hornbill, known locally as the ‘Julang Sumba.’ This large, colorful bird is a symbol of the island and plays a significant role in local folklore. Birdwatchers will be enchanted by the opportunity to spot these magnificent creatures in their natural habitat. Sumba's marine biodiversity is equally impressive. The surrounding waters are home to a variety of marine life, including vibrant coral reefs teeming with fish, sea turtles, and even occasional encounters with dolphins and whales. The island's coral reefs are a snorkeler's paradise, offering a glimpse into the underwater beauty of the region.",
      Humman_History_and_Cultures:"The history of Sumba is deeply intertwined with its indigenous cultures, traditions, and unique rituals. The island is home to several distinct ethnic groups, each with its own language, customs, and belief systems. The Sumbanese people are known for their enduring megalithic traditions. These rituals involve intricate ceremonies and the construction of stone tombs and monuments to honor the deceased. Visitors can witness these unique practices and gain insight into the cultural significance of megaliths in Sumbanese society. The island's indigenous cultures are deeply connected to its natural surroundings. The Marapu belief system, for example, centers on ancestral spirits and the worship of nature. These spiritual practices continue to shape the way of life in Sumba, from agricultural practices to architecture and art. Sumba's textile traditions are also renowned. The island is famous for its handwoven fabrics, particularly the 'ikat' textiles. These textiles are created using intricate dyeing and weaving techniques and often hold great cultural significance. Visitors can explore local villages to observe the artistry of Sumbanese weavers and even acquire these exquisite textiles as souvenirs.",
      cards: [
        {
          cardImage: cardImageSumba1,
          cardTitle: "Praijing Village"
        },
        {
          cardImage: cardImageSumba2,
          cardTitle: "Wairinding Hill"
        },
        {
          cardImage: cardImageSumba3,
          cardTitle: "Pasola Festival"
        },
        {
          cardImage: cardImageSumba4,
          cardTitle: "Ratenggaro Village"
        }
        // Add more cards as needed
      ],


      list: [
        'Praijing Village and Praijing Cultural Festival: Immerse yourself in the megalithic culture of Sumba by visiting Praijing Village, where ancient stone tombs and monuments are still actively maintained.',
        'Watu Mandorak Beach: Relax on the pristine Watu Mandorak Beach, known for its unique coastal rock formations and rugged beauty.',
        'Pantai Marosi: Discover Pantai Marosi, a secluded beach renowned for its world-class surf breaks and untouched landscapes.',
        'Lairuru Waterfall: Embark on a trek to the enchanting Lairuru Waterfall, hidden deep within the islands interior, and take a refreshing dip in its crystal-clear pools.',
        'Ratenggaro Village: Explore the traditional village of Ratenggaro, where you can witness Sumbanese architecture and attend traditional ceremonies.',
        'Surfing and Water Sports: Enjoy world-class surfing and water sports on Sumbas pristine beaches, such as Nihi Sumba Beach.',
        'Wairinding Hill: Hike to the summit of Wairinding Hill to witness breathtaking panoramic views of Sumbas landscapes and coastline.',

      ]},
  ];
  
  export default destinations;