import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Jumbotron from '../components/Jumbotron/Jumbotron'
import bgimg from '../assets/bg.jpg'
import safetyimg from '../assets/artoftravel/safety.png'
import travelimg from '../assets/artoftravel/travel.jpg'
import bittenbykomodoimg from '../assets/artoftravel/bittenbykomodo.jpg'
import komodoimg from '../assets/artoftravel/komodo.jpg'
import insectimg1 from '../assets/artoftravel/insect1.jpg'
import insectimg2 from '../assets/artoftravel/insect2.jpg'
import climateimg1 from '../assets/artoftravel/climate1.jpg'
import climateimg2 from '../assets/artoftravel/climate2.jpg'
import travelbyairimg from '../assets/artoftravel/air.jpg'
import travelbyseaimg from '../assets/artoftravel/sea.jpg'
import travelbylandimg from '../assets/artoftravel/land.jpg'
import travelbylandimg2 from '../assets/artoftravel/land2.jpg'
import table1 from '../assets/artoftravel/table1.png'
import table2 from '../assets/artoftravel/table2.png'
import table3 from '../assets/artoftravel/table3.png'
import table4 from '../assets/artoftravel/table4.png'
import table5 from '../assets/artoftravel/table5.png'
const ArtOfTravel = () => {
    useEffect(() => {
        AOS.init({
          duration: 2000, // Set your default animation duration
          delay: 200,    // Set your desired delay before animations start
          once:true,
        });
      }, []);

  return (
    <div className="ArtOfTravel">
        <Jumbotron bgimg={bgimg} title="Art of Travel" backgroundPosition="bottom"/>
        <div className='container'>
            <div className="mtb">
                {/* TOURS */}
                <div className="">
                    <div className="" data-aos="fade-up">
                    <h2 className='title-holder'>TOURS</h2>
                    <p>“Whether your interests involve activity-specific day trips or extended, multi-facetted onshore and or offshore experiences, we are committed to design and deliver your perfect travel experience. We strive to ensure all our travel experiences provide a positive benefit to our guests, employees, and the communities we work with while minimizing our footprint on the environment. These latter benefits may involve local employment and materials sourcing, and or contributions to initiatives that improve environmental and social conditions.”</p>
                    </div>

                </div>
            
                {/* BOOKING */}
                <div className="">
                    <div className="" data-aos="fade-up">
                    <h2 className='title-holder'>BOOKING </h2>
                    <ol>
                        <li><p>Reserving your trip with Komodo Travel and Transportation is easy. Simply fill the form out at the bottom of this page and send or contact us via email at: contact@komodotraveller.com</p></li>
                        <li><p>We will endeavour to contact you the next business day to confirm the details of your travel; subsequently develop a mutually agreed itinerary; confirm pricing; and finally complete your reservation subject to the terms of payment.</p></li>
                    </ol>
                    </div>
                    <div className="" data-aos="fade-up">
                        <h2>Payments, Cancellations & Refunds</h2>
                        <ol>
                            <li><p>In general, for trips booked at least 30 days in advance of the 1st day of your itinerary, payment in full must be made within 5 days of KTT’s email confirming the agreed upon itinerary and trip quote, unless otherwise agreed by KTT.</p></li>
                            <li><p>For trips booked less than 30 days from date of travel, payment in full is required within 48 hours of booking.</p></li>
                            <li><p>Clients wishing to cancel their booking must promptly notify Komodo Travel and Transportation by email and have received an “Acknowledgement of Cancellation” by email from us. In the event of a guest cancellation, we will refund any third-party portions of the booking (e.g. hotels, boats, transportation services, guide/host fees) we can recover, less a 10% - 20% cancellation fee, or at our guests discretion, apply the recoverable costs to a future booking with us.</p></li>
                            <li><p>In such case as KTT cancels a trip or trip segment we will refund the full value of the KTT package if the full trip is cancelled, or that portion appliable to the affected trip segment; or offer our guests the opportunity to rebook the same or equivalent value trip or trip segment.  Clients will not be able to make claims for compensation from consequential losses such as changes to flight tickets to the KTT Package trip departure point.</p></li>
                            <li><p>In such cases where, due to unforeseen circumstance, an activity or service (including accommodation and transportation) specified in the approved itinerary must be changed (e.g. for reasons of safety, extreme weather or other) we will seek to find a suitable, mutually agreed alternate, or at our discretion, offer our guests a refund commensurate with any costs recoverable by us.</p></li>
                        </ol>
  
                    </div>
                    <div className="" data-aos="fade-up">
                        <h2>Unused Services</h2>
                        <ol>
                            <li><p>One a trip is underway, should guests decide to shorten their trip, for whatever reason, Komodo Travel and Transportation is not obliged to make any kind of reimbursement for trips, which are interrupted or shortened by participants themselves, for any reason whatsoever.</p></li>
                            <li><p>At our discretion, however, we may consider refunding any portions for which we can recover our costs.</p></li>
                        </ol>        
                    </div>
                    <div className="" data-aos="fade-up">
                        <h2>Insurance</h2>
                        <p>Komodo Travel and Transportation recommends you obtain appropriate travel health insurance and trip cancellation and delay insurance, as well as baggage loss and damage coverage prior to departure.</p>
                        
                    </div>
                    <div className="" data-aos="fade-up">
                        <h2>Pricing</h2>
                        <p>All package prices are quoted in US Dollars. Trip payment on deposit, after all bank fees, must be as per the quote. Komodo Travel and Transportation reserves the right to make price adjustments in situations beyond our control, such as sudden fuel price increases, significant currency fluctuations, or a secondary service provider becomes unavailable. In this latter case, we will endeavour to first find an alternate provider delivering a near equivalent product at a similar price as was provided for in the original quote, where this results in reduction in our costs we will pass these savings on to our customers.</p>
                        
                    </div>
                    <div className="" data-aos="fade-up">
                        <h2>Planning & Preparing</h2>
                        <p>Indonesia is an exotic, physically, biologically and culturally diverse country that provides many opportunities for easy and truly inspiring travel. Proper planning and understanding of conditions and factors affecting travel in the amazing country will allow you to enjoy the most from your travel experience with us. A brief summary of some of these considerations are provide below:</p>
                    </div>

                </div>
            
            </div>             
            <div className="border-bot"></div>
            {/* HEALTH AND SAFETY */}
            <div className="mtb">
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h1>Health and Safety</h1>
                </div>
            
                <p data-aos="fade-right">Whether your interests involve extended onshore and or offshore travel by more traditional means or day-tripping by private jet or helicopter we are committed to design and safely deliver your perfect travel experience. </p>
                <div className="img-container">
                    <img src={safetyimg} className='gambar-cover' alt="" />
                </div>

  

                {/* GENERAL HEALTH */}
                <div className="general-health">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>General Health, Fitness and Special Medical Needs</h2>
                        <p>Where strenuous activities are anticipated as part of your travel with us, we highly advise doing some pre-conditioning for at least a month prior to travel, in order that you get the most out of your trip. Please advise us of any particular, medical conditions you or travel companions may have that could require emergency treatment, or otherwise create and emergency situation. As required by our guests, we can include nursing or other medical support as part of your travel. This is ideally arranged well in advance of your travel to ensure support availability. Be mindful during your travel and ensure you have travel insurance adequate to cover your activities.</p>
                    </div>
                </div>
                {/* Medical Services and Support */}
                <div className="Medical-Services">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Medical Services and Support</h2>
                        <p>Generally well-serviced and supported hospitals, medical clinics and dentists are present on all major islands and population centres in Indonesia. Please advise us, however, if you have particular health conditions, including allergies we should be aware of well-prior to your booking and or travel.  Arrangements can also be made for private nursing support during your travel. All guests should ensure they have adequate health and travel insurance prior to travel.</p>
                        <p>Always travel with a small personal first aid kit with basic supplies. Useful items include: sanitizer/alcohol swabs, antibiotic cream, bandages & dressings for small to medium cuts and scrapes, triangle bandage and or large bandana, first aid tape, pain reliever, anti-diarrhea medicine, personal prescriptions, coral-friendly sunscreen, aloe vera, insect repellent, oral antihistamine , corticosteroid cream, eye and ear drops, oral dehydration salts.</p>
                    </div>
                </div>
                {/* Medical Services and Support */}
                <div className="Medical-Services">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Safety, Security and Medical Advisory Services</h2>
                        <p>Up-to-date travel advisories on health and safety for international travellers is available from numerous sources including the World Health Organization as well as some governments and travel insurance providers. Examples of some of these resources can be accessed through the links below:  </p>
                        <a href="https://www.who.int/health-topics/health-security/#tab=tab_1"><h4>World Health Organization</h4></a>
                        <a href="https://travel.gc.ca/travelling/advisories"><h4>Government of Canada </h4></a>
                        <a href="https://travel.state.gov/content/travel/en/traveladvisories/traveladvisories.html/"><h4>Government of USA</h4></a>
                        <a href="https://www.smartraveller.gov.au/destinations/asia/indonesia"><h4>Government of Australia</h4></a>
                        <a href="https://consular-protection.ec.europa.eu/travel-advice_en"><h4>European Union</h4></a>
                    </div>
                </div>
                {/* Hygiene */}
                <div className="Hygiene">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Hygiene</h2>
                        <p>KTT takes extra care to help ensure any accommodations and dining experiences observe a high degree of cleanliness to help reduce the risk of hygiene-related health issues. Guests are advised to be attentive to their own hygiene and carry what sanitizers and medicines they find effective. All cuts or scrapes should be promptly cleaned, followed by use of an antibiotic cream and sterile bandage.   </p>

                    </div>
                </div>
                {/* Malaria/Dengue/ Bacterial Diarrhea and others */}
                <div className="Malaria">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Malaria/Dengue/ Bacterial Diarrhea and others</h2>
                        <p>Indonesia, like virtually all tropical countries, plays host to a wide range of diseases including dengue, malaria and hygiene-related bacterial diarrhea. While nationally dengue has been reported to be declining, increases have lately been observed in North Sumatra, Riau, West Kalimantan, North Kalimantan, North Sulawesi, Bali and Jakarta. While malaria can occur throughout Indonesia it is currently more common in Papua, East Nusa Tenggara, Maluku, North Maluku and West Papua.</p>

                        <p>Risk of contracting mosquito and fly-borne diseases can best be mitigated by use repellents, mosquito nets, screened living spaces, and fans. Active mosquito control programs are commonly undertaken by most higher-end accommodations. Vaccines are available in some locations for dengue and malaria under very specific conditions. There are several drugs that can be taken as a malarial prophylaxis and travellers are advised to consult travel medical professionals prior to their trip.</p>

                        <p>There are a very wide range of other viral, bacterial and parasitic diseases present in Indonesia, the risk of exposure can often be reduced by good hygiene, drinking only clean water, consuming properly prepared foods, eating only cooked meats, wearing gloves and foot coverings where appropriate, avoiding contact wild animals (alive or dead), and seeing a doctor if you develop any unusual symptoms. Increasing inflammation, swelling, or pain, around a wound or bite is never a good sign. </p>
                        <p>References:</p>
                        <ul>
                            <li><p><a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6753303/">Lee J., and Ryu, J.S. 2019. Current Status of Parasite Infections in Indonesia: A Literature Review. Korean J Parasitol. 2019 Aug;57(4):329-339. doi: 10.3347/kjp.2019.57.4.329. Epub 2019 Aug 31. PMID: 31533400; PMCID: PMC6753303. </a></p></li>
                            <li><p><a href="https://www.cdc.gov/globalhealth/countries/indonesia/default.html">Centers for Disease Control and Prevention (CDC): Global Health – Indonesia.</a></p> </li>
                            <li><p><a href="https://www.expat.or.id/medical/traveladvicetoindonesia.html">Medical Advice for Travelers to Indonesia: Disease Concerns and Health Precautions. Living IN Indonesia , A Site for Expatriates. </a></p></li>
                        </ul>

                    </div>
                </div>

                {/* Other Potentially Hazardous & Pesky Fauna and Flora */}
                <div className="otherpotent">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Other Potentially Hazardous & Pesky Fauna and Flora</h2>
                        <div className="flex basis-full flex-row-column gap-5">
                            <img src={komodoimg} className='gambar-cover gambar-cover-bottom' alt="" />
                            <img src={bittenbykomodoimg} className='gambar-cover' alt="" />
                        </div>
                        <p>While Indonesia has a wide range of potentially dangerous native wild species of flora and fauna the chance of encounter is typically extremely low. This list includes larger mammals such as Sumatran and Bornean Pygmy Elephants, Sumatran Tiger, Javan rhinoceros and wild pigs. Most travellers would feel lucky to encounter any of these under the right circumstance.  The use of local, well-trained guides is the best way to minimize risks of adverse encounters while enjoying habitats where these species still find home.  Indonesia has recently embarked on a country wide rabies elimination program, however, travellers should always avoid animals that are behaving unusually. Walking or hiking with a stout walking stick is never a bad idea,</p>

                        <p>Indonesia is home to several reptile species that can present varying degrees of risk when encountered by surprise or when going for that macho selfie.  These include: Komodo dragon, saltwater crocodile, Eastern Brown Snake, Taipan, white-lipped Malayan pit viper, Blue (or Malayan) Krait, King Cobra, Javan Spitting Cobra. Indonesian reticulated python and sea snakes.  Of these species, the Komodo dragon has gained celebrity status and is a very popular bucket list species that can be seen at Komodo National Park as well as lesser-known locations on Flores Island.   Trekking in Komodo National Park can only be done when accompanied by a licensed KNP guide.  Documented cases of snake bite in Indonesia are exceedingly rare in Indonesia.</p>

                        <p>Aside from mosquitoes, Indonesia boasts an enormous diversity of insect life but reportedly no poisonous spiders or particularly dangerous scorpions. Making up for that to some degree are “Rove Beetles” sometimes referred to as “Tomcat” Beetles. These are small, relatively slender, soft bodied beetles often red and black or simply black in coloration that can inflict an uncomfortable rash on your skin if not removed carefully.  A new species of giant, venomous wasp (Megalara garuda) was recently discovered on the Indonesian island of Sulawesi. Fortunately Wallace’s giant bee (Megachile pluto) , the largest known species of bee in the world,  and found in  Indonesia’s North Maluccan Islands, is quite rare and docile. More likely to be encountered, and more aggressive when disturbed, is the Asian giant honeybee (Apis dorsata) found in Borneo.</p>

                        <div className="flex direction-column text-align-center">
                            <div className="flex basis-full flex-row-column gap-5">
                                <img src={insectimg1} className='gambar-cover gambar-cover-bottom' alt="" />
                                <img src={insectimg2} className='gambar-cover' alt="" />
                            </div>
                            <p>Rove Beetles</p>


                        </div>
                        <p>The exceptionally diverse marine waters of Indonesia area also home to approximately 200 species of shark. Other species that can, on rare occasion, result in injury to the unwary adventurer include stone fish, lionfish, fire coral, sea urchins, cone shells, and Indonesian sea wasps (a jellyfish). As a general rule: know what to look for; be self-aware; avoid handling, stepping on, or otherwise making contact with marine creatures including corals; wear protective clothing e.g. dive skins, neoprene booties, gloves.</p>
                        <p>While there are many poisonous plants in Indonesia, very few result in contact-based reactions. Of this latter group the stinging plant “Pulus (Laportea stimulans) can leave a painful rash and blistering on skin contact. This species is common in Sumatra and western Java and can be readily identified.</p>
                    </div>
                    
                </div>
                {/* Volcanoes & Earthquakes  */}
                <div className="volcano">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Volcanoes & Earthquakes</h2>
                        <p>Indonesia lies within the “Ring of Fire” due to a complex and active tectonic environment involving the collision of two continental plates and two oceanic plates. This geological contest has resulted in formation of well over a hundred active volcanoes and numerous earthquakes each year. Volcanoes are largely absent from Borneo/Kalimantan, Sulawesi and New Guinea but form major tourist attractions in Java, Bali, Sumatra, Lombok and Flores. Eruptions and earthquakes are hard to predict and can generate conditions that adversely affect air travel, ground travel and general safety.</p>
                        <p>The largest volcanic eruption in recorded history is believed to be the eruption of Mount Tambora on the Indonesian island of Sumbawa in 1815. The eruption was a VEI-7 (Volcanic Explosivity Index) event, which is the second-highest level of explosive power. The eruption began on April 5, 1815, with a series of smaller explosions, followed by a massive explosion on April 10. The eruption produced a column of ash and gas that reached a height of 43 kilometers (27 miles) into the atmosphere and ejected an estimated 160 cubic kilometers (38 cubic miles) of material, including ash, rock, and pumice. The 1883 VEI-6 eruption of Krakatoa in Indonesia is also among the top 5 historic volcanic events and generated a tsunami killing around 36,000 people.</p>
                        <p>A few key measures to take to reduce your risk while travelling in Earthquake and Volcano prone areas in Indonesia:</p>
                        <ol>
                            <li><p>Know where to find emergency exits, evacuation routes, and safe zones in your accommodations and the surrounding area (e.g. open areas or sturdy doorways).</p></li>
                            <li><p>Be familiar with your accommodation’s emergency procedures and muster location.</p></li>
                            <li><p>Avoid accommodations that appear conspicuously compromised. </p></li>
                            <li><p>Pack an emergency kit - Prepare an emergency kit with essential items such as water, food, first aid supplies, a flashlight, particulate mask(s), a well charged cell phone and power bank, Keep the kit in an easily accessible location.</p></li>
                            <li><p>Stay informed - Stay tuned to local news and alerts to be aware of any earthquake warnings or updates. Listen to instructions from your guide.</p></li>
                            <li><p>Drop, cover, and hold on - If you feel an earthquake, drop to the ground, take cover under a sturdy piece of furniture, and hold on until the shaking stops. Avoid windows, doors, and elevators.</p></li>
                            <li><p>If immediate access to an exit, leave the building. </p></li>
                            <li><p>If there is a tsunami warning or other emergency, evacuate to higher ground or follow instructions from local authorities.</p></li>
                            <li><p>Be cautious after the earthquake - After the earthquake, be cautious when moving around and avoid damaged areas, downed power lines, and other hazards.</p></li>
                            <li><p>Wear protective clothing and a dust mask to avoid inhaling ash and other volcanic materials.</p></li>
                            <li><p>During times of volcanic activity, earthquake, and or extreme precipitation, avoid areas that may be subject to slope failure, flooding, inundation by lava, debris and pyroclastic flows. In the case of volcanoes low lying areas may also attract hazardous accumulations of heavier toxic gases such as sulphur dioxide, hydrogen sulfide, carbon dioxide and hydrochloric acid. </p></li>
                        </ol>

                    </div>
                </div>
                {/* Climate*/}
                <div className="climate">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Climate</h2>
                        <div className="flex direction-column text-align-center align-item-center">
                            <div className="flex basis-full flex-row-column align-item-center gap-50">
                                <div className='flex direction-column'>
                                    <img src={climateimg1} className='gambar-contain' alt="" />
                                    The lovely Nefetari applying sunscreen to a sun-burned, <br /> “Ra” during a hypothetical trading mission to Indonesia in the 1200’
                                </div>
                                <div className='flex direction-column'>
                                    <img src={climateimg2} className='gambar-contain' alt="" />
                                    Snow on Indonesia’s highest mountain, Puncak Jaya : Source Wikipedia
                                </div>
                            </div>
                        </div>
                        <p>Indonesia has a warm, tropical climate where the traveller can be at risk of sun burn, dehydration, hyperthermia and heat stroke. While sweating or otherwise moistening ones skin can cool one down through evaporative cooling, in very humid environments this process is less effective and risk of heat stroke increases. Timing certain activities for cooler times of day is a great way to reduce risk. As Noel Coward’s 1931 classic ditty advises, only “mad dog’s and Englishmen go out in the mid-day sun”. If you have no choice but to venture out  then some combination of shade and breeze seeking, sunscreen (coral-friendly), a hat, long-sleeved clothing, bandana, hand fans, umbrella, pith helmet etc are all useful, along with adequate drinking water and potential access to rehydration salts. Be mindful of the symptoms of heat exhaustion. Banda Aceh on the North tip of Sumatra, has been identified as on average, the warmest city in Indonesia, with temperatures averaging at 36.4 C, however, in terms of temperature extremes the hottest temperatures measured from 1949 to July 2022 have been reported by the Maumere weather station on Flores Island.  The current record temperature, 43.1C, was  apparently recorded there In September 2001.</p>
                        <p>Despite Indonesia’s prevailing warmth, one can still be at risk of hypothermia after prolonged exposure to cooler water or during wet and windy conditions..  Around Bali for example, sea surface temperatures typically range from 27 C – 30 C, cooler indeed than you normal core temperature.</p>
                        <p> The coldest day measured from 1949 to July 2022 , Oo C, was reported by the FakFak weather station in West Papua in June 2009. FakFak lies at an altitude of 130 meters above sea level.  As a general trend temperature drops by close to 10C for each 1000m gain in elevation.  Snow is still commonly encountered at the top of Indonesia’s highest mountain, he 4,884 m asl Puncak Jaya in Lorenze National Park, Papua. Examples of other locations with seasonally cool temperatures include the Dieng Plateau and Malang  , in Java, Ruteng in West Flores, Gayo Lues in North Sumatra, Mulea in Papua. Malino Highlands in South Sulawesi, Kota Seribu between Mount Lokon and Mount Mahawu in North Sulawesi and the tops of any of Indonesia’s many volcanoes. </p>
                        <p>Being able to dry off and stay dry after getting wet is critical. Having the ability to put on multiple layers, proper rain and or wind jackets/pants, gloves, hat, and using a multi layers of clothes are all good. Always pre-plan and prepare for the location you are going and, an old Cowboy once said, “ don’t get separated from your outfit” .</p>



                    </div>
                </div>
                {/* Trafic */}
                <div className="Traffic">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Traffic</h2>
                        <p>By far the highest risk factor associated with travel in Indonesia is associated with driving. This risk factor is exacerbated in densely populated urban centres as well as along the countless steep, narrow and windy roads found on most island. Conditions can be even more hazardous during rainy, windy weather where visibility can be more limited and there is added risk of tree fall, washouts and landslides.  While some travellers revel in the thrill of driving on new roads in new places, the safest approach is to generally use reliable experienced local drivers and well-maintained and equipped vehicles. Good local drivers can be a wealth of local knowledge and custom, know out of the way and interesting places, and be very helpful in the event of an accident or other adverse encounters.</p>



                    </div>
                </div>

                {/* Crime, Corruption & Terrorism*/}
                <div className="crime">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Crime, Corruption & Terrorism</h2>
                        <p>With reasonable care, Indonesia is an extremely safe, welcoming and inspiring destination for all travellers. Not being self-aware, being careless with belongings or valuables, staying in the wrong accommodation, being drunk and disorderly, violating the laws of the land, using abusive language with locals, flagrant disregard for local customs and sensitivities, getting involved with deals that seem too good to be true, traveling solo without a local guide or guides, and entrusting too much to strangers are all largely avoidable risk factors. Indonesia is also highly sensitive to terrorism and crime against foreign visitors and maintains both conspicuous and inconspicuous presence of security services in most areas.</p>



                    </div>
                </div>

                {/* General Safety*/}
                <div className="General-safety">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>General Safety</h2>
                        <p>Examples of questions to ask yourself:</p>
                        <ul>
                            <li><p>What are the hazards in this location and or with this activity and am I equipped physically, mentally and technically (right clothing and gear) to deal with them?</p></li>
                            <li><p>For the short or long journey I am about to go on, do I have the key items?</p></li>
                            <li><p>Is whatever I am about to sit in, ride on, lean on, push on, or walk on, structurally sound for the load, positionally secure, and is my balance and footing secure? (don’t forget…always maintain three points of contact)</p></li>
                            <li><p>Can I adequately see or feel where I am going or what I am doing?</p></li>
                            <li><p>Am I allowing myself to be distracted while doing an activity that could result in injury?</p></li>
                            <li><p>Have I checked he weather?</p></li>
                            <li><p>What will I do if a fire (while awake or sleeping) or crowd panic event happens in the space you are in or are adjacent to?</p></li>
                            <li><p>Is there likely to be serious hygiene problem at this location? (note: some of the best hotels have the worst hygiene, but not where we readily see it)</p></li>
                            <li><p>Is this animal or person behaving oddly?</p></li>
                            <li><p>Do I know what it is I am about to eat or drink?</p></li>
                            <li><p>What if someone I am with or near has a health or safety emergency?</p></li>
                            <li><p>What if we break down here? </p></li>
                            <li><p>Is this offer too good to be true?</p></li>
                            <li><p>Is this the expected route to the destination?</p></li>
                        </ul>



                    </div>
                </div>
                {/* Responsibility */}
                <div className="Responsibility">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Responsibility</h2>
                        <h3>Komodo Trave & Transportation endeavors to ensure your travel with us is safe, comfortable, and consistent with the agreed upon travel itinerary.  However, upon paying for your travel with us, you are absolving us and our associated service providers of any liability associated with personal physical or property damage or losses occurring during your travel with us. </h3>



                    </div>
                </div>
            </div>

            <div className="border-bot"></div>
            
            {/* Transportation and Accommodation */}
            <div className="mtb">
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h1>Transportation and Accommodation</h1>
                </div>
                <div className="img-container">
                    <img src={travelimg} className='gambar-cover' alt="" />
                </div>
            
                <p data-aos="fade-right">Komodo Travel & Transportation strives to ensure your travels are safe, comfortable, and efficient. We recognize too, that the mode of travel itself, is very much a part of the travel experience as is the journey. Travel, not just the “getting there” is an opportunity to soak up the natural and cultural context of the places we visit,  to reflect on our own lives and perspectives.  To quote the inimitable Tom Waits, “I’ve seen it all through the yellow windows of the evening train”.  While we can organize “self-guided” experiences, your journey with us, can become all the richer when accompanied by our knowledgeable local guides and hosts.  </p>
                
                {/* Air */}
                <div className="Air">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Air</h2>
                        <img src={travelbyairimg} className='gambar-cover gambar-cover-bottom' alt="" />
                        <p>Indonesia hosts some 230 airports. All major Indonesian islands are served by daily scheduled flights often by multiple carriers. Most international flights and certain inter island travel rely on airports in Java, Bali, Sumatra and Sulawesi. Primary hubs are:  </p>
                        <ul>
                            <li><p>Soekarno-Hatta International Airport (CGK) in Jakarta, Java</p></li>
                            <li><p>Juanda International Airport (SUB) in Surabaya, East Java</p></li>
                            <li><p>Ngurah Rai International Airport (DPS) in Denpasar, Bali</p></li>
                            <li><p>Kualanamu International Airport (KNO) in Medan, Sumatra</p></li>
                            <li><p>Sultan Hasanuddin International Airport (UPG) in Makassar, Sulawesi</p></li>

                        </ul>
                        <p>Some Indonesian locations are served daily by multiple flights and airlines while those more off the beaten track may be served less frequently.  Allow yourself plenty of extra time on the day of travel to and from any Indonesian location. Our guests from time to time require us to book private charter fixed wing and rotary aircraft where time is more limited.</p>
                        <p>Weather cancellations or delays can occur, particularly in the winter months. Unpredictable volcanic activity can also cause travel delays. </p>
                        <p>Komodo Travel & Transportation can arrange all internal flight requirements associated with your travel, as well as VIP service at local airports, to the extent allowed local airport authorities. We also frequently engage with local qualified charter companies providing first class, private fixed wing aircraft and helicopter services as required. Use of private charter aircraft in your travel can make the most of your holiday time, allow you ease of access and allow you to travel on your own schedule. </p>

                    </div>
                </div>
                {/* Sea */}
                <div className="Sea">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Travel by Sea</h2>
                        <img src={travelbyseaimg} className='gambar-cover gambar-cover-bottom' alt="" />
                        <p>Indonesia offers among the world’s finest opportunities for yachting, water-based sports and explorations.  Travel by traditional yacht, super yacht, submersible, or even kayak and outrigger can be an ideal way to island-hop and or explore Indonesia’s magical coastal locations “at the back of beyond”.  Indonesia also offers diverse opportunities for travel on her inland waters by raft, canoe and live aboard riverboats.</p>
                        <p>In every case, we ensure all vessels and operators are “fit for purpose” through boat inspections and captain and crew interviews, and referral. We pay particular attention to: vessel license/certificates; crew knowledge of the destinations and communication skills; overall state of maintenance; operating procedures; back-up equipment; cpompanion vessels/equipment; communications; first aid supplies/training; guest quarters; bathrooms/bathroom amenities; gally and dining areas; marine safety equipment, and hygiene.</p>

                    </div>
                </div>
                {/* Land */}
                <div className="Land">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Travel by Land</h2>
                        <img src={travelbylandimg} className='gambar-cover gambar-cover-bottom' alt="" />
                        <p>Travel overland in Indonesia can be enjoyed on foot, horse and virtually every form of wheeled device from unicycle to ultra-luxury car, van or bus. In every case, we ensure your safety, comfort and that equipment and drivers/guides are suited to the journey and level of service requirements.  While opportunities for self-guided overland travel can be arranged, for most of our guests, we strongly advise driver-supported travel due to the challenging driving conditions the country can present.  </p>
                        <img src={travelbylandimg2} className='gambar-cover gambar-cover-bottom' alt="" />


                    </div>
                </div>
                {/* Accomodation */}
                <div className="Accomodation">
                    <div className="" data-aos="fade-right">
                        <h2 className='title-holder'>Accommodations</h2>
                        
                        <p>Indonesia offers a full spectrum of accommodations, from glamping, beach huts, and homestays, to eco-resorts, boutique hotels, 5 Star hotels, and private villas. A wide range of luxury live aboard yachts and traditional “Phinisi” style boats are available. A few key points: </p>
                        <ul>
                            <li><p>More popular accommodations and certain bespoke transportation options can and do fill-up during peak travel times. Planning and booking early is always a good idea.</p></li>
                            <li><p>Confirm whether there are any significant sources of noise pollution impinging on the accommodation.</p></li>
                            <li><p>No matter where you stay, be mindful about safety, security and hygiene.</p></li>
                            <li><p>Know where alternate exits are and ensure they are viable in case of fire or earthquake. </p></li>
                            <li><p>Be courteous to the staff, even when frustration occur.</p></li>
                            <li><p>When requesting special services or arrangements, never accept a nod of the head or simple “Yes” , ask for your host to describe what they intend to do.</p></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="border-bot"></div>

            {/* Useful References and Phrases */}
            <div className="mtb">
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h1>Useful References and Phrases</h1>
                </div>

            
                <p data-aos="fade-right">As it is said in Bahasa "Tak kenal maka tak sayang" ..”You can't love what you don't know” in other words: it helps to know someone or something to better appreciate and or love them. Prior to your travel, gaining knowledge about your destinations  locations’ geography, ecology, culture, history, points of interest, and recent news is a great start. Sources of destination knowledge of course include the internet including Wikipedia, and any number of travel guides and published histories, and of course acquaintances who have recently visited the destination. Mastering a few basic phrases in Bahasa will also enhance the quality of your engagement with local people.  Some useful references are provided below. </p>
                <a href="https://www.indonesia.travel/gb/en/news/new-international-travel-regulations-to-enter-indonesia-as-of-29-november-2021"><h2>Government of Indonesia Travel Link</h2></a>

                <div className="title text-align-center"  data-aos="fade-right" >
                    <h2>Examples of Recommended Reading</h2>
                </div>
                <div className="flex flex-row-column basis">
                    <div>
                        <h3>General Travel</h3>
                        <ol>
                            <li><p>Indonesia Etc.: Exploring the Improbable Nation, by Elizabeth Pisani. W.W. Norton & Company, 2014</p></li>
                            <li><p>Lonely Planet Indonesia, by Tim Bewer, Kate Morgan, Stuart Butler, Anna Kaminski, and Loren Bell. Lonely Planet, 2019 (updates frequently)</p></li>
                            <li><p>Bali: Sekala & Niskala, by Fred B. Eiseman Jr. Periplus Editions, 1990.</p></li>
                            <li><p>Bali: The Imaginary Museum of the Island, by Francine Brinkgreve. Oxford University Press, 1993.</p></li>
                            <li><p>Indonesia: A Personal Journey, by David Bowden. Tuttle Publishing, 2010.</p></li>
                            <li><p>Beyond Bali: Sublime Indonesia, by Diana Darling. Chronicle Books, 1998.</p></li>

                        </ol>
                        <h3>Archaeology and Pre-History</h3>
                        <ol>
                            <li><p>Indonesian Megaliths: A Forgotten Cultural Heritage, by Jean-Paul Barbier. Yayasan Cipta Loka Caraka, 1996.</p></li>
                            <li><p>The Prehistoric Rock Art of Indonesia, by Robert Layton. Ohio University Press, 1992.</p></li>
                            <li><p>Prehistoric Indonesia: A Reader, edited by Peter Bellwood, James J. Fox, and Darrell Tryon. Routledge, 1995.</p></li>
                            <li><p>Ancient Indonesian Art of the Central and Eastern Javanese Periods, by Helena Spanjaard. Koninklijk Instituut voor Taal-, Land- en Volkenkunde, 2008.</p></li>
                            <li><p>Indonesia's Megalithic Culture, by Jan Fontein. Bijdragen tot de Taal-, Land- en Volkenkunde, 1980.</p></li>

                        </ol>
                    </div>
                    <div>
                        <h3>Humman History</h3>
                        <ol>
                            <li><p>Indonesia: A Modern History, by Adrian Vickers. Palgrave Macmillan, 2013.</p></li>
                            <li><p>A History of Modern Indonesia Since c. 1300, by M.C. Ricklefs. Palgrave Macmillan, 2001.</p></li>
                            <li><p>In the Time of Madness: Indonesia on the Edge of Chaos, by Richard Lloyd Parry. Grove Press, 2007.</p></li>
                            <li><p>The Making of Southeast Asia: International Relations of a Region, edited by Amitav Acharya and Barry Buzan. Cornell University Press, 2012.</p></li>
                            <li><p>Sumatra: Its History and People, by William Marsden. Oxford University Press, 2012.</p></li>
                            <li><p>Java: A History of the Island, by Jan Gonda. Princeton University Press, 1986.</p></li>
                            <li><p>Divine Dyads: Ancient Civilization in Indonesia, by Pauline E. van der Zee. Sidestone Press, 2019.</p></li>
                            <li><p>Violent Order: Understanding Rebel Governance Through Indonesia's Darul Islam Movement, by Zachary Abuza. Johns Hopkins University Press, 2016.</p></li>
                            <li><p>The Korowai of Irian Jaya: Their Language in Its Cultural Context, by Gerrit J. van Enk and Lourens de Vries. Oxford University Press, 1997.</p></li>
                            <li><p>Strangers in the Mist: Tales of War and Peace from Indonesia's Hidden Valley, by Nigel Randell. Little, Brown Book Group, 2009.</p></li>
                            <li><p>West Papua and Indonesia since Suharto: Independence, Autonomy, or Chaos?, edited by Peter King. University of New South Wales Press, 2004.</p></li>
                            <li><p>Borneo: Memory and Identity, by Wendy M.K. Shaw. University of Hawai'i Press, 2003.</p></li>
                        </ol>
                    </div>
                    
                </div>
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h2>Culture & Art</h2>
                </div>
                <div className="flex flex-row-column basis">
                    <div>
                        <ol>
                            <li><p>Indonesian Philosophy: Bridging Worlds Through Culture, by Dwi Noverini Djenar. Springer, 2011.</p></li>
                            <li><p>Ancient Beliefs and Modern Superstitions: A Critique of Contemporary Indonesian Intellectual Discourse, by Azyumardi Azra. Mizan Pustaka, 1999.</p></li>
                            <li><p>Culture Shock! Indonesia: A Survival Guide to Customs and Etiquette, by Susan Rodgers. Marshall Cavendish Editions, 2010.</p></li>
                            <li><p>The Philosophy of Java, by Clifford Geertz. Princeton University Press, 1960.</p></li>
                            <li><p>Indonesian Sea Nomads: Money, Magic, and Fear of the Orang Suku Laut, by Cynthia Chou. Routledge, 2018.</p></li>
                            <li><p>The Art of Southeast Asia: Cambodia, Vietnam, Thailand, Laos, Burma, Java, Bali, by Philip Rawson. Thames & Hudson, 1990.</p></li>
                            <li><p>The Cultural Atlas of Indonesia, by Leo Suryadinata, Evi Nurvidya Arifin, and Aris Ananta. Equinox Publishing, 2019.</p></li>
                            <li><p>The Lontar Anthology of Indonesian Drama, edited by John H. McGlynn. Lontar Foundation, 2010.</p></li>
                            <li><p>The Music of Indonesia, by Philip Yampolsky. Routledge, 1991.</p></li>
                            <li><p>Gamelan Gong Kebyar: The Art of Twentieth-Century Balinese Music, by Michael Tenzer. University of Chicago Press, 2000.</p></li>
                            <li><p>Traditional Architecture of Indonesia, by Barry Dawson. Thames & Hudson, 1994.</p></li>
                            <li><p>Indonesian Heritage Series: Architecture, by Marianne Yaldiz. Archipelago Press, 1999.</p></li>
                            <li><p>Indonesian Houses: Tradition and Transformation in Vernacular Architecture, by Avianti Armand. Editions Didier Millet, 2009. </p></li>
                            <li><p>Indonesian Food: 50 of the Best Indonesian Dishes You Should Try, by Daniel Humphreys. CreateSpace Independent Publishing Platform, 2016.</p></li>
                            <li><p>Traditional Indonesian Textiles, by John Gillow and Barry Dawson. Thames & Hudson, 1995.</p></li>
                        </ol>
                    </div>
                    <div>
                        <h3>Fiction</h3>
                        <ol>
                            <li><p>Supernova: The Knight, the Princess, and the Falling Star, by Dewi Lestari. Amazon Crossing, 2016.</p></li>
                            <li><p>Beauty is a Wound, by Eka Kurniawan. New Directions, 2015.</p></li>
                            <li><p>Man Tiger, by Eka Kurniawan. Verso, 2015.</p></li>
                            <li><p>Saman: A Novel, by Ayu Utami. Hyperion Books, 1999.</p></li>
                            <li><p>Beyond the Horizon: Short Stories from Contemporary Indonesia, edited by Maggie Tiojakin. Lontar Foundation, 2015.</p></li>
                        </ol>
                    </div>
                </div>
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h2>Natural History</h2>
                </div>
                <div className="flex flex-row-column basis">

                    <div>
                        <h3>General</h3>
                        <ol>
                            <li><p>The Malay Archipelago, by Alfred Russel Wallace. Periplus Editions, 2008.</p></li>
                            <li><p>The Spice Islands Voyage: In Search of Wallace, by Tim Severin. Abacus, 1998.</p></li>
                            <li><p>The Ecology of Sulawesi, edited by Tony Whitten, Stuart Hawkins, and John E. M. Baars. Periplus Editions, 2000.</p></li>
                            <li><p>The Ecology of Kalimantan, edited by K. Eduard Linsenmair, Oliver Parthasarathy, and Herbert Sukopp. Periplus Editions, 2000.</p></li>
                            <li><p>The Ecology of Java and Bali, by Tony Whitten. Oxford University Press, 1996.</p></li>
                            <li><p>The Natural History of Orang-Utans, by John MacKinnon. Andromeda Oxford Ltd, 1974.</p></li>
                            <li><p>Reefs and Rain Forests: The Natural Heritage of Malaysian Borneo, by John MacKinnon and Karen Phillipps. Oxford University Press, 1997.</p></li>
                            <li><p>The Ecology of Papua: Part One, by Andrew J. Marshall and Bruce M. Beehler. Periplus Editions, 2007.</p></li>
                            <li><p>Papua's Hidden Treasures: A Biological Exploration of Papua New Guinea, by Peter J. Unmack and Michael P. Hammer. University of Papua New Guinea Press, 2016.</p></li>
                            <li><p>Trees and Forests of Tropical Asia. Peter Ashton and David Lee. The University of Chicago Press. 2022.</p></li>

                        </ol>
                        <h3>Reptiles & Amphibians</h3>
                        <ol>
                            <li><p>Reptiles and Amphibians of Southeast Asia, by Indraneil Das. New Holland Publishers, 2010.</p></li>
                            <li><p>A Field Guide to the Snakes of Borneo, by Robert F. Inger and Tan F. Lim. Natural History Publications (Borneo), 2010.</p></li>
                            <li><p>A Guide to Amphibians and Reptiles of the Krakatau Islands, by Djoko T. Iskandar and Jimmy A. McGuire. Raffles Bulletin of Zoology, 2000.</p></li>
                        </ol>
                        <h3>Mamals</h3>
                        <ol>
                            <li><p>A Field Guide to the Mammals of Borneo, by Junaidi Payne and Charles M. Francis. Sabah Society, 1985.</p></li>
                            <li><p>Mammals of Southeast Asia, by Charles M. Francis. Princeton University Press, 2008. •  A Photographic Guide to the Reptiles and Amphibians of Java, Bali and Surrounding Islands, by J. Van Rooijen and J. Vogel. Chimaira, 2008.</p></li>
                            <li><p>he Mammals of Sulawesi, edited by Anang S. Achmadi and Tim F. Flannery. Australia National University Press, 2008.</p></li>
                        </ol>
                    </div>
                    <div>
                        <h3>Plants</h3>
                        <ol>
                            <li><p>A Naturalist’s Guide to the Trees of Southeast Asia. Saw Leng Guon. John Beaufoy Publishing. Penang Botanic Gardens. 2019.</p></li>
                            <li><p>Plants of Southeast Asia: A Field Guide to the Trees, Shrubs, Palms, Climbers, Bamboos and Herbaceous Plants of Southeast Asia, by Tony Lamb and Miguel David de Leon. Timber Press, 2014.</p></li>
                            <li><p>Vegetation  and  Ethnobotany  of  Bali. Kuswata Kartawinata, Roemantyo, Ary Prihardhyanto Keim, & Wawan Sujarwo. Sujarwo–Jakarta:  BRIN Publishing. 265 pp. ISBN 978-623-7425-73-1 (e-book). </p></li>
                            <li><p>The Flora of Java, Volume I-III, by J.J. Smith. N.V.P. Noordhoff, 1977.</p></li>
                            <li><p>Wild Flowers of Bali, by Ria van der Meijden. Periplus Editions, 1997.</p></li>
                            <li><p>Orchids of Sumatra, by J. B. Comber. Royal Botanic Gardens, Kew, 2001.</p></li>
                            <li><p>Field Guide to the Pitcher Plants of Sumatra and Java, by Charles Clarke. Redfern Natural History Productions, 2006.</p></li>

                        </ol>
                        <h3>Insects</h3>
                        <ol>
                            <li><p>The Insects of Indonesia, by H. Franz and G. Franz. Brill, 2016.</p></li>
                            <li><p>Butterflies of the Malay Peninsula: A Pocket Guide, by Alexander Monastyrskii and Paul </p></li>
                            <li><p>Zborowski. Periplus Editions, 2008.</p></li>
                            <li><p>The Butterflies of Sulawesi: Annotated Checklist for a Critical Island Fauna, by George T. </p></li>
                            <li><p>Austin. Brill, 2008.</p></li>
                            <li><p>Butterflies of Indonesia, by T. Barlow. Periplus Editions, 2003.</p></li>
                            <li><p>The Moths of Borneo: Family Notodontidae, by M. Fibiger and A. Hausmann. Brill, 2007.</p></li>
                            <li><p>Beetles of Eastern Indonesia: An Introduction to the Families of Coleoptera, by M. A. Jäch and I. Balke. E.J. Brill, 2008.</p></li>
                         

                        </ol>
                    </div>
                    <div>
                        <h3>Birds</h3>
                        <ol>
                            <li><p>A Photographic Guide to Birds of Indonesia. Morten Strange. Tuttle Publishing 2012.</p></li>
                            <li><p>Birds of the Indonesian Archipelago: Greater Sundas and Wallacea, by James A. Eaton, Bas van Balen, and Nick W. Brickle. Lynx Edicions, 2016.</p></li>
                            <li><p>A Photographic Guide to Birds of Java, Sumatra and Bali, by Paul Jepson and Indonesian Images. New Holland Publishers, 2001.</p></li>
                            <li><p>Field Guide to the Birds of Borneo, Sumatra, Java and Bali, by John MacKinnon and Karen Phillipps. Oxford University Press, 1993.</p></li>
                            <li><p>A Field Guide to the Birds of Peninsular Malaysia and Singapore, by Allen Jeyarajasingam and Alan Pearson. Oxford University Press, 1999.</p></li>
                            <li><p>A Photographic Guide to Birds of Borneo, by G. W. H. Davison and Chew Yen Fook. New Holland Publishers, 2008.</p></li>
                            <li><p>A Naturalist's Guide to the Birds of Indonesia, by Gadjah Mada University Press. John Beaufoy Publishing, 2015.</p></li>

                        </ol>
                        <h3>Marine Fish and Invertebrates</h3>
                        <ol>
                            <li><p>Reef Fishes of the Indo-Pacific. Matthias Bergbauer and Manuela Kirschner. John Beaufoy Publishing  Limited. 2014.</p></li>
                            <li><p>Coral Reef Fishes: Indo-Pacific and Caribbean, by Ewald Lieske and Robert Myers. HarperCollins Publishers, 2001.</p></li>
                            <li><p>The Comprehensive Guide to Marine Life of Indonesia, by Allen Collins and Mark Erdmann. Tuttle Publishing, 2021</p></li>
                            <li><p>Marine Fishes of Tropical Australia and South-east Asia, by Gerry Allen. Western Australian Museum, 1997.</p></li>
                            <li><p>A Field Guide to the Seashells of Java, Bali and Sumatra, by Timothy O’Hara. The Natural History Museum, London, 2002.</p></li>
                            <li><p>Indonesian Shells I: Gastropods, by D.W. Dance. Verlag Christa Hemmen, 1983.</p></li>
                            <li><p>Indonesian Shells II: Pelecypods and Scaphopods, by D.W. Dance. Verlag Christa Hemmen, 1986.</p></li>

                        </ol>
                        
                    </div>

                    
                </div>
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h2>More</h2>
                </div>
                <div>                        
                    <img src={table1} className="container" alt="" />
                    <img src={table2} className="container" alt="" />
                    <img src={table3} className="container" alt="" />
                    <img src={table4} className="container" alt="" />
                </div>
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h2>Greetings</h2>
                    <p>While greetings in English are widely understood in Indonesia, native Indonesian’s have a range of greetings, each associated with their culture. The more common are listed below</p>
                </div>
                <div>                        
                    <img src={table5} className="container" alt="" />

]
                </div>
                <div className="title text-align-center"  data-aos="fade-right" >
                    <h2>Some Grammar Rules and Sounds</h2>
                </div>
                <ul>
                    <li><p>Adjectives always follow the noun, e.g. beautiful car - mobil cantik.</p></li>
                    <li><p>Word order is usually subject-verb-object (e.g. Saya(I) mau(want) makan(to eat) nasi(rice) - means I want to eat nice; and the personal pronoun goes after the noun (mobil saya is "m y car")</p></li>
                    <li><p>Respect is shown when addressing, so the custom is to address an older man as "bapak" or "pak",  an equal/younger man as "mas", an older woman as "ibu" or "bu", younger woman as "nona", married woman as "nyonya".</p></li>

                </ul>
            </div>
            
        </div>
    </div>
    
  )
}

export default ArtOfTravel