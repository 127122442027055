import './Geography.css'

import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
const Geography = () => {

    useEffect(() => {
        AOS.init({
          duration: 2000, // Set your default animation duration
          delay: 200,    // Set your desired delay before animations start
          once:true,
        });
      }, []);
      
  return (
    
    <div className='container'>
        <div className="title text-align-center geography-text"  data-aos="fade-up" >
            <h1>GEOGRAPHY</h1>
        </div>
        <div className="geography-text" data-aos="fade-up">
          <p>Indonesia, the world's largest archipelago, covers over 1.8 million square kilometers, comprising 17,000 islands, with about half being inhabited. The country boasts 147 volcanoes, 76 of which are active, and Mount Kerinci and Puncak Jaya are the highest points. Lake Toba is the largest lake, and Lake Matano is the deepest. Well-known destinations include Bali, Java, Papua, and Sumatra. Indonesia's diverse landscapes, biodiversity, and cultures are shaped by plate tectonics, climate, symbiosis, and human influence.</p>
        </div>
        <div className="card-wrapper flex gap-5">
            <div className="card"data-aos="fade-right" data-aos-delay="0">
                <div className="img geo-card-img1">

                </div>
                <div className="card-text">
                    <h1>Danau Matano on Sulawesi</h1>
                    <p>The deepest lake in Indonesia</p>

                </div>
            </div>
            <div className="card"data-aos="fade-right" data-aos-delay="200">
                <div className="img geo-card-img2">

                </div>
                <div className="card-text">
                    <h1>Puncak Jaya on Papua</h1>
                    <p>The highest volcano in Indonesia</p>
                </div>
            </div>
            <div className="card"data-aos="fade-right" data-aos-delay="400">
                <div className="img geo-card-img3">

                </div>
                <div className="card-text">
                    <h1>Lake Toba in Sumatra</h1>
                    <p>The largest lake in Indonesia</p>
                </div>
            </div>

            
        </div>

    </div>
  )
}

export default Geography