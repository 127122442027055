import React from 'react'
import './Footer.css'
import Logo from '../../assets/Logo.png'
const Footer = () => {
  return (
    <div className="footer align-item-center">
      <div className="mtb footer-wrapper">
        <div className="container gap-50 flex space-between flex-wrap">
          <div className="footer-content logo">
            <img src={Logo} className='logo' alt="" />
            <h3>Find Us on social media </h3>
            <p>Instagram</p>
            <p>Whatsapp</p>
            <p>Email</p>
          </div>
          <div className="footer-content">
            <h2>Navigate</h2>
            <p>Home</p>
            <p>About Us</p>
            <p>Art Of Travel</p>
            <p>Map</p>
            <p>Special Events</p>
            <p>Contact Us</p>


          </div>
          <div className="footer-content">
            <h2>Destinations</h2>
            <p>Flores Komodo</p>
            <p>Bali</p>
            <p>Sumatra</p>
            <p>Jawa</p>
            <p>Sulawesi</p>
            <p>Lombok</p>
            <p>Papua</p>
            <p>Sumbawa</p>
            <p>Kalimantan</p>
            <p>Sumba</p>
            
            
            

          </div>
        </div>

      </div>
      <div className="">
        <h4>© 2024 by Komodo Traveller</h4>

      </div>
    </div>
  )
}

export default Footer