import React from 'react';
import './TeamCard.css'
const TeamCard = ({ id, image, fb, wa, name, born, language, description }) => {
  return (
    <div className="team-card">
        <div className="team-card-pic">
            <img src={image} className='card-image' alt={`${name}'s profile`} />
        </div>
        <div className="team-card-text padding-1">
            <h2>{name}</h2>
            <p>Birth:{born}</p>
            <p>Language:{language}</p>

            <ul>
                {description.list.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
            </ul>
        </div>         
    </div>

  );
};

export default TeamCard;
