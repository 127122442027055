import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
const Booking = () => {
    useEffect(() => {
        AOS.init({
          duration: 2000, // Set your default animation duration
          delay: 200,    // Set your desired delay before animations start
          once:true,
        });
      }, []);
  return (
    <div className='container'>
          <div className="" data-aos="fade-up">
          <h2 className='title-holder'>BOOKING </h2>
          <p>1.	Reserving your trip with Komodo Travel and Transportation is easy. Simply fill the form out at the bottom of this page and send or contact us via email at: contact@komodotraveller.com </p>
          <p>2.	We will endeavour to contact you the next business day to confirm the details of your travel; subsequently develop a mutually agreed itinerary; confirm pricing; and finally complete your reservation subject to the terms of payment.</p>
        </div>
        <div className="" data-aos="fade-up">
            <h2>Payments, Cancellations & Refunds</h2>
            <p>1.	In general, for trips booked at least 30 days in advance of the 1st day of your itinerary, payment in full must be made within 5 days of KTT’s email confirming the agreed upon itinerary and trip quote, unless otherwise agreed by KTT.  </p>
            <p>2.	For trips booked less than 30 days from date of travel, payment in full is required within 48 hours of booking. </p>
            <p>3.	Clients wishing to cancel their booking must promptly notify Komodo Travel and Transportation by email and have received an “Acknowledgement of Cancellation” by email from us. In the event of a guest cancellation, we will refund any third-party portions of the booking (e.g. hotels, boats, transportation services, guide/host fees) we can recover, less a 10% - 20% cancellation fee, or at our guests discretion, apply the recoverable costs to a future booking with us. </p>
            <p>4.	In such case as KTT cancels a trip or trip segment we will refund the full value of the KTT package if the full trip is cancelled, or that portion appliable to the affected trip segment; or offer our guests the opportunity to rebook the same or equivalent value trip or trip segment.  Clients will not be able to make claims for compensation from consequential losses such as changes to flight tickets to the KTT Package trip departure point.</p>
            <p>5.	In such cases where, due to unforeseen circumstance, an activity or service (including accommodation and transportation) specified in the approved itinerary must be changed (e.g. for reasons of safety, extreme weather or other) we will seek to find a suitable, mutually agreed alternate, or at our discretion, offer our guests a refund commensurate with any costs recoverable by us. </p>
        </div>
        <div className="" data-aos="fade-up">
            <h2>Unused Services</h2>
            <p>1.	One a trip is underway, should guests decide to shorten their trip, for whatever reason, Komodo Travel and Transportation is not obliged to make any kind of reimbursement for trips, which are interrupted or shortened by participants themselves, for any reason whatsoever. </p>
            <p>2.	At our discretion, however, we may consider refunding any portions for which we can recover our costs.</p>

            
            
            
            
            
        </div>
        <div className="" data-aos="fade-up">
            <h2>Insurance</h2>
            <p>Komodo Travel and Transportation recommends you obtain appropriate travel health insurance and trip cancellation and delay insurance, as well as baggage loss and damage coverage prior to departure.</p>
            
        </div>
        <div className="" data-aos="fade-up">
            <h2>Pricing</h2>
            <p>All package prices are quoted in US Dollars. Trip payment on deposit, after all bank fees, must be as per the quote. Komodo Travel and Transportation reserves the right to make price adjustments in situations beyond our control, such as sudden fuel price increases, significant currency fluctuations, or a secondary service provider becomes unavailable. In this latter case, we will endeavour to first find an alternate provider delivering a near equivalent product at a similar price as was provided for in the original quote, where this results in reduction in our costs we will pass these savings on to our customers..</p>
            
        </div>
        <div className="" data-aos="fade-up">
            <h2>Planning & Preparing</h2>
            <p>Indonesia is an exotic, physically, biologically and culturally diverse country that provides many opportunities for easy and truly inspiring travel. Proper planning and understanding of conditions and factors affecting travel in the amazing country will allow you to enjoy the most from your travel experience with us. A brief summary of some of these considerations are provide below:</p>
            
        </div>
    </div>
  )
}

export default Booking