import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useParams } from "react-router-dom";
import DestinationCard from "../../components/Card/DestinationCard";
import destinations from "./destinationsData"; 
import Section from "../../components/Section/Section"
import geo from "../../assets/geo.jpg"
import humman from "../../assets/humman.jpg"
import biodiversity from "../../assets/biodiversity.jpg"

import './Destination.css'
const Destinations = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000, // Set your default animation duration
      delay: 200,    // Set your desired delay before animations start
      once:true,
    });
  }, []);
  const flexDirection = 'row-reverse';
  const leftBackgroundPosition = 'left'


  const { id } = useParams();
  // console.log("ID:", id);
  const destination = destinations.find((dest) => dest.id === parseInt(id));
  return (
    <>
      {destination ? (
        <div className="destination flex direction-column gap-50">
          {/* jumbotron */}
          <div className='destination-jumbotron'>
            <div
              style={{
                backgroundImage:`url(${destination.bg})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                height: '400px',
              }}
            >
              <div className="bgdark flex title text white ">
                <h1>{destination.name}</h1>
              </div>
            </div>
          </div>
          {/* container */}
          <div className="container">
            {/* Destination Brief */}
            <div className="flex flex-wrap destination-desc-wrapper">
              <div className="destination-box">
                <img src={destination.descImage} className="align-items-center destination-intro-img" alt="" />
              </div>
              <div className="destination-box">
                <div className="destination-desc">
                  <h1>{destination.name}</h1>
                  <p>{destination.desc}</p>
                  <a href=""className='section-btn'>Contact Us</a>

                </div>
              </div>
            </div>
          </div>
          {/* section no container */}
          <div className="flex direction-column gap-50">
            <Section 
              title="Topography and Geology" 
              desc={destination.Topography_and_Geology} 
              bg={geo}
            />
            <Section 
              title="Biodiversity" 
              desc={destination.Biodiversity} 
              bg={biodiversity}
              
              />
            <Section 
              title="Human History and Cultures" 
              desc={destination.Humman_History_and_Cultures} 
              bg={humman}/>
          </div>
          
          

        </div>
        // <div>
        //     <div
        //       style={{
        //         backgroundImage:`url(${destination.bg})`,
        //         backgroundSize: 'cover',
        //         backgroundRepeat: 'no-repeat',
        //         backgroundPosition: 'center',
        //         height: '400px',
        //       }}
        //     >
        //        <div className="bgdark flex title text white ">
        //         <h1>{destination.name}</h1>
        //       </div>
        //     </div>
            // <div className='container mtb flex align-item-center'>
            //   <div className="m-top-bottom destination-intro justify-content-center flex align-item-center flex-wrap" data-aos="fade-right">
            //     <div className="half">
            //       <img src={destination.descImage} className="align-items-center destination-intro-img" alt="" />
            //     </div>
            //     <div className="half flex direction-column geography-text" >
            //       <h1>{destination.name}</h1>
            //       <p>{destination.desc}</p>
            //     </div>
            //   </div>
              
        //       <div className="flex">

              //   <Section 
              //     title="Topography and Geology" 
              //     desc={destination.Topography_and_Geology} 
              //     bg={geo}
                  
                  
              //     />
              //   <Section 
              //     title="Biodiversity" 
              //     desc={destination.Biodiversity} 
              //     bg={biodiversity}
              //     flexDirection={flexDirection}
              //     leftBackgroundPosition={leftBackgroundPosition}
              //     />
              //   <Section 
              //     title="Human History and Cultures" 
              //     desc={destination.Humman_History_and_Cultures} 
              //     bg={humman}/>
              // </div>

        //       {/* Tour */}
        //       <div>

        //         <div className="" data-aos="fade-right">
        //           <h1>Tour</h1>
        //           <p>explore and enjoy the diverse culture, nature and uniqueness of {destination.name}</p>
        //         </div>
        //         <div className="flex flex-wrap align-item-center justify-content-center gap-5">
        //           {destination.cards.map((card, index) => (
        //             <DestinationCard
        //               key={index}
        //               cardImage={card.cardImage}
        //               cardTitle={card.cardTitle}
        //             />
        //           ))}

        //         </div>
                
        //         <p data-aos="fade-right">Some {destination.name} Travel Highlights</p>
        //         <ul data-aos="fade-right">
        //           {destination.list.map((item, index) => (
        //             <li key={index}>{item}</li>
        //           ))}
        //         </ul>
        //       </div>

        //     </div>
        // </div>
      ) : (
        <div>
          <h2>Destination not found</h2>
        </div>
      )}
    </>
  )
}

export default Destinations