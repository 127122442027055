import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './Section.css'
// import bg from '../../assets/geo.jpg'
const Section = ({ title, desc, bg, flexDirection }) => {
    useEffect(()=>{
        AOS.init({duration:2000});
    }, []);
    const sectionStyle = {
        
        flexDirection: flexDirection || 'column', // Default to 'column' if no value is provided
      };
    const sectionBg ={
        backgroundImage: `url(${bg})`,
    }
  return (
    <div className='section-wrapper'>
      <div className="section-bg container" style={sectionBg}>

      </div>
        <div className="section-desc flex direction-column gap-5"  >
            <div className="title"  data-aos="fade-up">
                <h1>{title}</h1>
            </div>
            <p  data-aos="fade-up">{desc}</p>
            
        </div>
    </div>
  )
}

export default Section