import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import Jumbotron from '../components/Jumbotron/Jumbotron'
import bgimg from '../assets/bg.jpg'

import Geography from '../components/Geography/Geography'
import Biodiversity from '../components/Biodiversity/Biodiversity'
import Human from '../components/Human/Human'
import ImageSlider from '../components/ImageSlider/ImageSlider'
const Home = () => {
  useEffect(()=>{
    AOS.init({
      duration: 800, // Set your default animation duration
      delay: 200,    // Set your desired delay before animations start
      once:true,
    });
  }, []);
  return (
    <div>
        {/* <Jumbotron bgimg={bgimg} title="Komodo Travel & Transportation (KTT)" backgroundPosition="bottom"/>   */}
        <ImageSlider />

        <div className="mtb home-section container title text-align-center" data-aos='fade-up'>
          <h2>Komodo Travel & Transportation (KTT) recognizes that holiday time is among the most precious time for shared experience with family, friends and colleagues. We design and deliver customized, travel experiences throughout Indonesia with utmost consideration of your safety and comfort. All travel is tailored to your particular interests and requirements.</h2>
        </div>
        <div className="border-bot"></div>
        <div className="mtb ">
          <Geography />          
        </div>
        <div className="border-bot"></div>
        <div className="mtb">
          <Biodiversity />
        </div>
        <div className="border-bot"></div>
        <div className="mtb">

          <Human />
          
        </div>


    </div>
  )
}

export default Home