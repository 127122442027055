import React, {useEffect} from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './Jumbotron.css'

const Jumbotron = ({ bgimg,backgroundPosition, ...props }) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${bgimg})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    // minHeight: '500px',
    backgroundPosition: backgroundPosition || 'center center',
    position: 'relative',
  
  };
  useEffect(()=>{
    AOS.init({duration:2000});
}, [])
  return (
    <div style={backgroundImageStyle}>
       <div className='white container'>
          <div className="flex title text">
            <h1>{props.title}</h1>
          </div>

       </div>
    </div>
  )
}

export default Jumbotron