
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from './pages/Home'
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Booking from "./pages/Booking";
import ContactUs from "./pages/ContactUs";
import ArtOfTravel from "./pages/ArtOfTravel";
import Destinations from "./pages/Destinations/Destinations";

import AboutUs from "./pages/AboutUs"
function App() {
  return (

      <body>
              <header id="header">
        <Navbar />
      </header>
      <Router>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About Us" element={<AboutUs />} />  
          <Route path="/Booking" element={<Booking />} />  
          <Route path="/ContactUs" element={<ContactUs />} />
          <Route path="/Destinations/:id" element={<Destinations />} />  
          <Route path="/ArtOfTravel" element={<ArtOfTravel />} />  


        </Routes>
      <footer id="footer">
        <Footer />
      </footer>
      </Router>
      </body>
  );
}

export default App;
